import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-558ae3c6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "accordion"
};
var _hoisted_2 = {
  "class": "accordion__tabs"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  key: 0,
  "class": "accordion__content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabNames, function (tab, index) {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      onClick: function onClick($event) {
        return _ctx.toggleTab(index);
      },
      "class": _normalizeClass({
        active: _ctx.activeTabIndex === index
      })
    }, _toDisplayString(tab.tabName), 11, _hoisted_3);
  }), 128))])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabNames, function (taby, indexX) {
    return _openBlock(), _createElementBlock("div", {
      key: indexX
    }, [_ctx.activeTabIndex === indexX ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, taby.slotName, {}, undefined, true)])) : _createCommentVNode("", true)]);
  }), 128))]);
}