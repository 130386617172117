import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["snackbar", {
      visible: _ctx.visible
    }]),
    style: _normalizeStyle({
      backgroundColor: _ctx.color,
      color: _ctx.textColor
    })
  }, _toDisplayString(_ctx.text), 7);
}