const state = {
            // camera
            showCamera: false,
            photo: null,
            smallPhoto: false,
            photoToApprove: null,
}

const mutations = {

    hideImagePanel(state) {
        state.showImage = false;
    },
    showImage(state, payload) {
        state.showImage = true;
        state.imageURL = payload;
    },
    minimizePhoto(state){
        state.smallPhoto = true;
    },
    maximizePhoto(state){
        state.smallPhoto = false;
    },
  
    updatePhotoToApprove(state, payload){
        state.photoToApprove = payload;
    },
    updatePhoto(state, payload){
        state.photo = payload;
        state.smallPhoto = true;
    },
    updateShowImageUploader(state, payload){
        state.showImageUploader = false;
        state.photo = null;
        state.photoToApprove = null;
        state.smallPhoto = false;
        setTimeout(() => {
        state.showImageUploader = payload;
        }, 100);
    },
    updateShowCamera(state, payload){
        console.log('triggetring camera');
        state.showCamera = false;
        state.photo = null;
        state.photoToApprove = null;
        state.smallPhoto = false;
        setTimeout(() => {
        state.showCamera = payload;
        }, 100);
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
