const state = {
  avatarPreloaded: localStorage.getItem("avatarPreloaded") === true,
  avatar: localStorage.getItem("avatar") != null ? localStorage.getItem("avatar") : null,
  avatarLocation: localStorage.getItem("avatarLocation") ? localStorage.getItem("avatarLocation") : null,
  avatarLocationDateTime: localStorage.getItem("avatarLocationDateTime") ? localStorage.getItem("avatarLocationDateTime") : null,
  avatarClothing: localStorage.getItem("avatarClothing") ? localStorage.getItem("avatarClothing") : null,
  avatarClothingColor: localStorage.getItem("avatarColor") ? localStorage.getItem("avatarColor") : null,
  avatarClothingDateTime: localStorage.getItem("avatarClothingDateTime") ? localStorage.getItem("avatarClothingDateTime") : null,
  blinking: false,
  fps: localStorage.getItem("fps") != undefined ? localStorage.getItem("fps") : 40, // used to control the speed of the animation
  speakingFramesToRemove: localStorage.getItem("speakingFramesToRemove") != undefined ? localStorage.getItem("speakingFramesToRemove") : 0,
  delayBeforeSpeakingInMS: localStorage.getItem("delayBeforeSpeakingInMS") != undefined ? localStorage.getItem("delayBeforeSpeakingInMS") : 0,
  timeSoundWaitingForAnimationToComplete: 0,
  timeAnimationWaitingForSoundToComplete: 0,
  resetMouthDefaults: false,
  isThinking: false,
  avatarSounds: [],
  avatarSoundsPreload: [],
  frameCurrent: 0,
  isNaked: false,
  avatarList: [
    {
      profileVideo: "assets/avatar/mary/animations/lookingaround_latest.webp",
      image: "assets/web/avatarSelector/mary.webp",
      // nudes: "assets/avatar/mary/xxx/1afasdfasdfsdfasdfasfdfds.png",
      nudes: "assets/avatar/mary/xxx/mary_dress_livingroom.jpg",
      name: "Mary",
      isFemale: true,
      XXX: false,
      firstTimeOutfit: "dress_1_full",
      liveVideoThumbnail: true,
      fullyAnimated: true,
      isPro: false,
      animated: false,
    },
    {
      profileVideo: "assets/avatar/kurt/animations/lookingaround_latest.webp",
      firstTimeOutfit: "suit",
      image: "assets/web/avatarSelector/kurt.webp",
      nudes:  "assets/web/avatarSelector/kurt.webp",
      name: "Kurt",
      XXX: false,
      isFemale: false,
      liveVideoThumbnail: true,
      fullyAnimated: true,
      isPro: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/sarah.webp",
      nudes: "assets/web/avatarSelector/sarah.webp",
      name: "Sarah",
      isFemale: true,
      XXX: true,
      liveVideoThumbnail: true,
      fullyAnimated: true,
      isPro: false,
      animated: false,
    },
    {
      profileVideo: "assets/avatar/marcus/animations/lookingaround_latest.webp",
      image: "assets/web/avatarSelector/marcus.webp",
      nudes: "assets/web/avatarSelector/marcus.webp",
      firstTimeOutfit: "suit",
      name: "Marcus",
      XXX: false,
      isFemale: false,
      fullyAnimated: true,
      liveVideoThumbnail: true,
      isPro: false,
      animated: false,
    },

    {
      image: "assets/web/avatarSelector/aria.webp",
      nudes: "assets/web/avatarSelector/aria.webp",
      name: "Aria",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    {
      image: "assets/web/avatarSelector/jade.webp",
      nudes: "assets/web/avatarSelector/jade.webp",
      name: "Jade",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    {
      image: "assets/web/avatarSelector/aurora.webp",
      nudes: "assets/web/avatarSelector/aurora.webp",
      name: "Aurora",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    // {
    //   image: "assets/web/avatarSelector/ava.webp",
    //   nudes: "assets/web/avatarSelector/ava.webp",
    //   name: "Ava",
    //   isFemale: true,
    //   XXX: false,
    //   liveVideoThumbnail: false,
    //   isPro: true,
    //   fullyAnimated: false,
    //   animated: true,
    // },
    {
      image: "assets/web/avatarSelector/charlotte.webp",
      nudes: "assets/web/avatarSelector/charlotte.webp",
      name: "Charlotte",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    {
      image: "assets/web/avatarSelector/emma.webp",
      nudes: "assets/web/avatarSelector/emma.webp",
      name: "Emma",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },

    {
      image: "assets/web/avatarSelector/amaya.webp",
      nudes: "assets/web/avatarSelector/amaya.webp",
      name: "Amaya",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/chloe.webp",
      nudes: "assets/web/avatarSelector/chloe.webp",
      name: "Chloe",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },

    {
      image: "assets/web/avatarSelector/emily.webp",
      nudes: "assets/web/avatarSelector/emily.webp",
      name: "Emily",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },

    // {
    //   image: "assets/web/avatarSelector/evelyn.webp",
    //   nudes: "assets/web/avatarSelector/evelyn.webp",
    //   name: "Evelyn",
    //   isFemale: true,
    //   XXX: false,
    //   liveVideoThumbnail: false,
    //   isPro: true,
    //   fullyAnimated: false,
    //   animated: true,
    // },
    {
      image: "assets/web/avatarSelector/grace.webp",
      nudes: "assets/web/avatarSelector/grace.webp",
      name: "Grace",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/hana.webp",
      nudes: "assets/web/avatarSelector/hana.webp",
      name: "Hana",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    {
      image: "assets/web/avatarSelector/hazel.webp",
      nudes: "assets/web/avatarSelector/hazel.webp",
      name: "Hazel",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    // {
    //   image: "assets/web/avatarSelector/isabella.webp",
    //   nudes: "assets/web/avatarSelector/isabella.webp",
    //   name: "Isabella",
    //   isFemale: true,
    //   XXX: false,
    //   liveVideoThumbnail: false,
    //   isPro: true,
    // },

    {
      image: "assets/web/avatarSelector/jessica.webp",
      nudes: "assets/web/avatarSelector/jessica.webp",
      name: "Jessica",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    {
      image: "assets/web/avatarSelector/keiko.webp",
      nudes: "assets/web/avatarSelector/keiko.webp",
      name: "Keiko",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/amelia.webp",
      nudes: "assets/web/avatarSelector/amelia.webp",
      name: "Amelia",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/kimiko.webp",
      nudes: "assets/web/avatarSelector/kimiko.webp",
      name: "Kimiko",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/lily.webp",
      nudes: "assets/web/avatarSelector/lily.webp",
      name: "Lily",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    // {
    //   image: "assets/web/avatarSelector/ella.webp",
    //   nudes: "assets/web/avatarSelector/ella.webp",
    //   name: "Ella",
    //   isFemale: true,
    //   XXX: false,
    //   liveVideoThumbnail: false,
    //   isPro: true,
    //   fullyAnimated: false,
    //   animated: false,
    // },
    {
      image: "assets/web/avatarSelector/madison.webp",
      nudes: "assets/web/avatarSelector/madison.webp",
      name: "Madison",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/mia.webp",
      nudes: "assets/web/avatarSelector/mia.webp",
      name: "Mia",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/natalie.webp",
      nudes: "assets/web/avatarSelector/natalie.webp",
      name: "Natalie",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/nia.webp",
      nudes: "assets/web/avatarSelector/nia.webp",
      name: "Nia",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/nicole.webp",
      nudes: "assets/web/avatarSelector/nicole.webp",
      name: "Nicole",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/nora.webp",
      nudes: "assets/web/avatarSelector/nora.webp",
      name: "Nora",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/olivia.webp",
      nudes: "assets/web/avatarSelector/olivia.webp",
      name: "Olivia",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/penelope.webp",
      nudes: "assets/web/avatarSelector/penelope.webp",
      name: "Penelope",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/ren.webp",
      nudes: "assets/web/avatarSelector/ren.webp",
      name: "Ren",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/savannah.webp",
      nudes: "assets/web/avatarSelector/savannah.webp",
      name: "Savannah",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    // {
    //   image: "assets/web/avatarSelector/scarlett.webp",
    //   nudes: "assets/web/avatarSelector/scarlett.webp",
    //   name: "Scarlett",
    //   isFemale: true,
    //   XXX: false,
    //   liveVideoThumbnail: false,
    //   isPro: true,
    //   fullyAnimated: false,
    //   animated: false,
    // },
    {
      image: "assets/web/avatarSelector/sophia.webp",
      nudes: "assets/web/avatarSelector/sophia.webp",
      name: "Sophia",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/stella.webp",
      nudes: "assets/web/avatarSelector/stella.webp",
      name: "Stella",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/suki.webp",
      nudes: "assets/web/avatarSelector/suki.webp",
      name: "Suki",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/tia.webp",
      nudes: "assets/web/avatarSelector/tia.webp",
      name: "Tia",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    // {
    //   image: "assets/web/avatarSelector/veronica.webp",
    //   nudes: "assets/web/avatarSelector/veronica.webp",
    //   name: "Veronica",
    //   isFemale: true,
    //   XXX: false,
    //   liveVideoThumbnail: false,
    //   isPro: true,
    //   fullyAnimated: false,
    //   animated: true,
    // },
    {
      image: "assets/web/avatarSelector/zoey.webp",
      nudes: "assets/web/avatarSelector/zoey.webp",
      name: "Zoey",
      isFemale: true,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: true,
    },
    // {
    //   image: "assets/web/avatarSelector/zuri.webp",
    //   nudes: "assets/web/avatarSelector/zuri.webp",
    //   name: "Zuri",
    //   isFemale: true,
    //   XXX: false,
    //   liveVideoThumbnail: false,
    //   isPro: true,
    // },

    {
      image: "assets/web/avatarSelector/liam.webp",
      nudes: "assets/web/avatarSelector/liam.webp",
      name: "Liam",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/alexander.webp",
      nudes: "assets/web/avatarSelector/alexander.webp",
      name: "Alexander",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/christopher.webp",
      nudes: "assets/web/avatarSelector/christopher.webp",
      name: "Christopher",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/daniel.webp",
      nudes: "assets/web/avatarSelector/daniel.webp",
      name: "Daniel",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/felix.webp",
      nudes: "assets/web/avatarSelector/felix.webp",
      name: "Felix",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/gabriel.webp",
      nudes: "assets/web/avatarSelector/gabriel.webp",
      name: "Gabriel",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/henry.webp",
      nudes: "assets/web/avatarSelector/henry.webp",
      name: "Henry",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/isaac.webp",
      nudes: "assets/web/avatarSelector/isaac.webp",
      name: "Isaac",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/james.webp",
      nudes: "assets/web/avatarSelector/james.webp",
      name: "James",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/john.webp",
      nudes: "assets/web/avatarSelector/john.webp",
      name: "John",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/kevin.webp",
      nudes: "assets/web/avatarSelector/kevin.webp",
      name: "Kevin",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/michael.webp",
      nudes: "assets/web/avatarSelector/michael.webp",
      name: "Michael",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/oliver.webp",
      nudes: "assets/web/avatarSelector/oliver.webp",
      name: "Oliver",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/patrick.webp",
      nudes: "assets/web/avatarSelector/patrick.webp",
      name: "Patrick",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    // {
    //   image: "assets/web/avatarSelector/ryan.webp",
    //   nudes: "assets/web/avatarSelector/ryan.webp",
    //   name: "Ryan",
    //   isFemale: false,
    //   XXX: false,
    //   liveVideoThumbnail: false,
    //   isPro: true,
    //   fullyAnimated: false,
    //   animated: false,
    // },
    {
      image: "assets/web/avatarSelector/samuel.webp",
      nudes: "assets/web/avatarSelector/samuel.webp",
      name: "Samuel",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
      fullyAnimated: false,
      animated: false,
    },
    {
      image: "assets/web/avatarSelector/thomas.webp",
      nudes: "assets/web/avatarSelector/thomas.webp",
      name: "Thomas",
      isFemale: false,
      XXX: false,
      liveVideoThumbnail: false,
      isPro: true,
    },
  ],
  // avatarList: [
  //     {
  //         name: 'mary',
  //         age: '28',
  //         pronoun: 'she',
  //         pronoun2: 'her',
  //         ethnicity: 'Hispanic',
  //         profileVideo: 'assets/avatar/mary/animations/lookingaround_latest.webp',
  //         firstTimeOutfit: 'dress_1_full',
  //         isAnimated: true
  //         // profileVideo: 'ass || crunchFiles === trueets/avatar/mary/primary/a/0.webp'

  //     },
  //     {
  //         name: 'marcus',
  //         age: '26',
  //         pronoun: 'him',
  //         pronoun2: 'he',
  //         ethnicity: 'British African',
  //         profileVideo: 'assets/avatar/marcus/animations/lookingaround_latest.webp',
  //         firstTimeOutfit: 'suit',
  //         isAnimated: true
  //         // profileVideo: 'ass || crunchFiles === trueets/avatar/mary/primary/a/0.webp'
  //     },
  //     {
  //         name: 'sarah',
  //         age: '32',
  //         pronoun: 'she',
  //         pronoun2: 'her',
  //         ethnicity: 'Asian American',
  //         profileVideo: 'assets/avatar/sarah/animations/lookingaround_latest.webp',
  //         firstTimeOutfit: 'vneck',
  //         isAnimated: true
  //         // profileVideo: 'ass || crunchFiles === trueets/avatar/mary/primary/a/0.webp'

  //     },
  //     {
  //         name: 'kurt',
  //         age: '28',
  //         pronoun: 'him',
  //         pronoun2: 'he',
  //         ethnicity: 'Irish American',
  //         profileVideo: 'assets/avatar/kurt/animations/lookingaround_latest.webp',
  //         firstTimeOutfit: 'suit',
  //         isAnimated: true
  //         // profileVideo: 'ass || crunchFiles === trueets/avatar/mary/primary/a/0.webp'

  //     },
  // ],
};

const mutations = {
  addAvatarSound(state, payload) {
    if (Array.isArray(payload) && payload[0] && payload[0].indexOf("quickadd") != -1 && state.isTalking) {
      return;
    }
    state.avatarSounds = payload;
  },
  updateIsNaked(state, payload){
    state.isNaked = payload;
  },
  updateAvatarSoundsPreload(state, payload) {
    state.avatarSoundsPreload = payload;
  },
  updateAvatar(state, payload) {
    state.avatar = payload.toLowerCase();
    localStorage.setItem("avatar", payload);
  },
  clearTalking(state) {
    state.avatarSounds = [];
  },
  updateAvatarPreloaded(state, payload) {
    state.avatarPreloaded = payload;
    localStorage.setItem("avatarPreloaded", payload);
  },
  updateAvatarLocation(state, payload) {
    state.avatarLocation = payload;
    localStorage.setItem("avatarLocation", payload);
  },
  updateAvatarLocationDateTime(state, payload) {
    state.avatarLocationDateTime = payload;
    localStorage.setItem("avatarLocationDateTime", payload);
  },
  updateAvatarClothingColor(state, payload) {
    state.avatarClothingColor = payload;
    localStorage.setItem("avatarClothingColor", payload);
  },
  updateAvatarClothing(state, payload) {
    state.avatarClothing = payload;
    localStorage.setItem("avatarClothing", payload);
  },
  updateAvatarClothingDateTime(state, payload) {
    state.avatarClothingDateTime = payload;
    localStorage.setItem("avatarClothingDateTime", payload);
  },
  adjustFPSSoundSlowerThanAnimation(state) {
    const delayInSeconds = state.timeSoundWaitingForAnimationToComplete;
    const currentFPS = state.fps;
    const adjustmentFactor = 1; // Change this value to adjust the sensitivity of the FPS adjustment
    const newFPS = currentFPS + adjustmentFactor * delayInSeconds;
    state.fps = newFPS;
    localStorage.setItem("fps", newFPS);
  },
  adjustFPSAnimationSlowerThanSound(state) {
    const delayInSeconds = state.timeAnimationWaitingForSoundToComplete;
    const currentFPS = state.fps;
    const adjustmentFactor = 1; // Change this value to adjust the sensitivity of the FPS adjustment
    const newFPS = currentFPS - adjustmentFactor * delayInSeconds;
    state.fps = newFPS;
    localStorage.setItem("fps", newFPS);
  },
  updateTimeSoundWaitingForAnimationToComplete(state, payload) {
    state.timeSoundWaitingForAnimationToComplete = payload;
  },
  updateTimeAnimationWaitingForSoundToComplete(state, payload) {
    state.timeAnimationWaitingForSoundToComplete = payload;
  },
  updateFPS(state, payload) {
    state.fps = payload;
    localStorage.setItem("fps", payload);
  },
  updateResetMouthDefaults(state, payload) {
    state.resetMouthDefaults = payload;
  },
  updateFrameCurrent(state, payload) {
    state.frameCurrent = payload;
  },
  updateAvatarList(state, payload) {
    state.avatarList = payload;
  },
  updateBlinking(state, payload) {
    state.blinking = payload;
  },
  updateDelayBeforeSpeakingInMS(state, payload) {
    state.delayBeforeSpeakingInMS = payload;
    localStorage.setItem("delayBeforeSpeakingInMS", payload);
  },
  updateSpeakingFramesToRemove(state, payload) {
    state.speakingFramesToRemove = payload;
    localStorage.setItem("speakingFramesToRemove", payload);
  },
};

const actions = {};

module.exports = {
  state,
  mutations,
  actions,
};
