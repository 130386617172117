const preChartData = {
    labels: ['January', 'February', 'March'],
    datasets: [{
        label: 'Data One',
        borderColor: 'black',
        data: [40, 20, 12],
        fill: false
    }]
};


const state = {
    programStatus: 'Waiting',
    chartData: preChartData,
    enableChartResponses: (localStorage.getItem('enableChartResponses') === 'true'),
    chartType: 'line',
    barChartData: {
        labels: ['January', 'February', 'March'],
        datasets: [{
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 20, 12]
        }]
    },
}

const mutations = {
    updateChartType(state, payload) {
        state.chartType = payload;
    },
    updateBarChartData(state, payload) {

        if (payload && payload.response && typeof payload.response === 'object' && payload.response.labels && payload.response.datasets && typeof payload.response.labels === 'object' && Array.isArray(payload.response.datasets)) {

            state.barChartData = payload.response;
            return;
        } else {

            try {
                payload.response = JSON.parse(payload.response);
                if (payload && payload.response && typeof payload.response === 'object' && payload.response.labels && payload.response.datasets && typeof payload.response.labels === 'object' && Array.isArray(payload.response.datasets)) {
        
                    state.barChartData = payload.response;
                    return;
                }
            }
            catch (error) {
                console.error('error in updateBarChartData ', JSON.stringify(error));
            }
        }

   
        try {
            if (payload && payload.response) {
                if (typeof payload.response === 'string') {
    
                    // if first character is [ and not { then remove it
                    if (payload.response.charAt(0) === '[' && payload.response.charAt(1) !== '{') {
                        payload.response = payload.response.substring(1);
                    }
                    // if last character is ] and not } then remove it
                    if (payload.response.charAt(payload.response.length - 1) === ']' && payload.response.charAt(payload.response.length - 2) !== '}') {
                        payload.response = payload.response.substring(0, payload.response.length - 1);
                    }

                    // remove extra empty spaces
                    //  payload.response = payload.response.replace(/(\r)/gm, '');
                    payload.response = JSON.parse(payload.response);
                }
                state.barChartData = payload.response;
                state.programStatus = typeof payload.response;
             
            } else {
                state.programStatus = "no response in payload: " + payload
            }
        }
        catch (e) {
           
            state.programStatus = "error parsing response: " + e
        }
    },
     
    enableChartResponses(state) {
        state.enableChartResponses = true;
        localStorage.setItem('enableChartResponses', true);
    },
    disableChartResponses(state) {
        state.enableChartResponses = false;
        localStorage.setItem('enableChartResponses', false);
    },
    updateViewChart(state, payload) {
        state.showChartView = payload;
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
