import "core-js/modules/es.array.concat.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-36eba23d"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "mainMenuNavigationItem"
};
var _hoisted_2 = {
  "class": "breadcrumbs"
};
var _hoisted_3 = {
  key: 0,
  "class": "breadcrumb_separator"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  "class": "mainMenuNavigationItem__title"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = ["onClick"];
var _hoisted_8 = {
  "class": "menuItems__label"
};
var _hoisted_9 = ["onClick"];
var _hoisted_10 = {
  "class": "menuItems__label"
};
var _hoisted_11 = ["onClick"];
var _hoisted_12 = {
  "class": "menuItems__label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, function (breadcrumb, index) {
    return _openBlock(), _createElementBlock("span", {
      key: "breadCrumb_".concat(index)
    }, [index > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_3, [_createVNode(_component_font_awesome_icon, {
      "class": "breadcrumb_separator-icon",
      icon: "fa-chevron-right"
    })])) : _createCommentVNode("", true), _createElementVNode("span", {
      "class": "breadcrumb_item",
      onClick: function onClick($event) {
        return _ctx.$emit('navChange', breadcrumb.clickEvent);
      }
    }, [breadcrumb.icon ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
      key: 0,
      icon: breadcrumb.icon
    }, null, 8, ["icon"])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(breadcrumb.label ? breadcrumb.label : "Main Menu"), 1)], 8, _hoisted_4)]);
  }), 128))]), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.breadcrumbs[_ctx.breadcrumbs.length - 1].label), 1), _ctx.menuItems.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, function (item, menuIndex) {
    return _openBlock(), _createElementBlock("div", {
      "class": _normalizeClass({
        menuItems__holder: true
      }),
      key: "".concat(menuIndex).concat(item.label)
    }, [item.clickEventType === 'link' && item.clickEventType !== 'method' ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      "class": _normalizeClass({
        'menuItems__item-container': true,
        'menuItems__item-container--first': menuIndex === 0
      }),
      onClick: function onClick($event) {
        return _ctx.launchWindow(item.clickEvent, item.label === 'Log Out');
      }
    }, [_createElementVNode("span", _hoisted_8, [item.icon ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
      key: 0,
      icon: item.icon,
      "class": "menuItems__icon"
    }, null, 8, ["icon"])) : _createCommentVNode("", true), _createTextVNode(_toDisplayString(item.label), 1)])], 10, _hoisted_7)) : _createCommentVNode("", true), item.clickEventType === 'method' ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      "class": _normalizeClass({
        'menuItems__item-container': true,
        'menuItems__item-container--first': menuIndex === 0
      }),
      onClick: function onClick($event) {
        return _this[item.clickEvent]();
      }
    }, [_createElementVNode("span", _hoisted_10, [item.icon ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
      key: 0,
      icon: item.icon,
      "class": "menuItems__icon"
    }, null, 8, ["icon"])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(item.label), 1)])], 10, _hoisted_9)) : _createCommentVNode("", true), item.clickEventType !== 'link' && item.clickEventType !== 'method' ? (_openBlock(), _createElementBlock("div", {
      key: 2,
      "class": _normalizeClass({
        'menuItems__item-container': true,
        'menuItems__item-container--first': menuIndex === 0
      }),
      onClick: function onClick($event) {
        return item.clickEventType === 'store' ? _ctx.$store.commit(item.clickEvent, item.clickValue) : _ctx.$emit('navChange', item.clickEvent);
      }
    }, [_createElementVNode("span", _hoisted_12, [item.icon ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
      key: 0,
      icon: item.icon,
      "class": "menuItems__icon"
    }, null, 8, ["icon"])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(item.label), 1)])], 10, _hoisted_11)) : _createCommentVNode("", true)]);
  }), 128))])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]);
}