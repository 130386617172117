
const state = {
    tokenScreenTitle: 'Well you are a lucky duck!',
    tokenScreenSubtitle1: 'You have just received',
    tokenScreenSubtitle2: 'Free tokens!',
    tokenScreenQuantity: 250,
    tokenScreenButtonText: 'Claim Now',
    showPurchaseTokens: false,
    showTokensFalling: false,
}

const mutations = {
    updateTokenScreen(state, payload) {
        state.tokenScreenTitle = payload.title;
        state.tokenScreenSubtitle1 = payload.subtitle1;
        state.tokenScreenSubtitle2 = payload.subtitle2;
        state.tokenScreenQuantity = payload.quantity;
        state.tokenScreenButtonText = payload.buttonText;
    },
    updateShowTokensFalling(state, payload) {
        state.showTokensFalling = payload;
    },
    updateShowPurchaseTokens(state, payload) {
        state.showPurchaseTokens = payload;
    }
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
