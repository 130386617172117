const axios = require('axios');
const qs = require("qs");
const state = {
    upgradeVoicesScreen: false,
    suppressSecondaryActions: false,
}

const mutations = {
    updateSuppressSecondaryActions(state, payload){
        state.suppressSecondaryActions = payload;
    },
    updateUpgradeVoicesScreen(state, payload){
        state.upgradeVoicesScreen = payload;
    },
}

const actions = {
   async reportEvent(context, event, specifics=null){
    if(!localStorage.getItem("security")){
        // console.error("no security token, can't send event");
        return;
    }
    var metadata = await context.dispatch("getMetadata");
    var useContext = context.state.useContext;
    var useMemories = context.state.useMemories;
    var storeMemories = context.state.storeMemories;
    var simpleResponses = context.state.simpleResponses;
            var dataConfig = {
                token: localStorage.getItem("security"),
                event: event,
                metadata,
                useContext,
                useMemories,
                storeMemories,
                'specifics':specifics,
                
                simpleResponses,
                // activeSubscriptionId: subscriptionDetails.subscriptionId,
                // activeSubscriptionPackage: subscriptionDetails.subscriptionPackage,
                // subscriptionType: subscriptionDetails.subscriptionType,
                // image: thisLocal.$store.state.photo,
                // liveVideo: thisLocal.$store.state.isCapturingVideo,
                // isTextQuestion: thisLocal.isTextQuestion,
              };
              const data = qs.stringify(dataConfig);
    
              var config = {
                method: "post",
                url: `https://dev.aivitar.com/actionEvent${context.state.testingAccount === true ? "Dev" : ""}`,
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                data: data,
              };
      
              try {
                await axios(config);
              }
              catch(error){
                console.error('error sending vote', error);
              }
        
    }
    
}

module.exports = {
    state,
    mutations,
    actions
}
