const state = {
    leaveReview: false,
    lastReviewPopupDate: localStorage.getItem('lastReviewPopupDate') != undefined ? localStorage.getItem('lastReviewPopupDate') : null,
}

const mutations = {
    updateLeaveReview(state, payload){

        if(payload === true){
            // get today's date
            var todaysDate = new Date();
            // make sure date format is -yyyy-mm-dd
            var formattedDate = todaysDate.toISOString().slice(0,10);
            if(formattedDate != state.lastReviewPopupDate){
                state.leaveReview = payload;
                localStorage.setItem('lastReviewPopupDate', formattedDate);
            }
        } else {
            state.leaveReview = false;
        }
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
