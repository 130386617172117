import { createApp } from "vue";
const axios = require("axios");
import App from "./App.vue";
import wb from "./registerServiceWorker";
import "@fortawesome/fontawesome-pro/css/all.css";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
// import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'
import "primeflex/primeflex.css";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/md-light-indigo/theme.css"; // Theme CSS
import "primevue/resources/primevue.min.css"; // Core CSS
import "primeicons/primeicons.css"; // Icons
// import Button from "primevue/button"
import Button from "primevue/button";
import ripple from "primevue/ripple";
import Styleclass from "primevue/styleclass";
import Avatar from "primevue/avatar";
import * as Sentry from "@sentry/vue";
/* eslint-disable */
import {
    faPlus,
    faPhone,
    faCog,
    // faNotebook,
    faCopy,
    faPlayCircle,
    faListCheck,
    faComment,
    faChartLine,
    faHeart,
    
    faEdit,
    faTrashAlt,
    faEnvelope,
    faStopCircle,
    faDownload,

    faPencil,
    faPaperPlane,
    // faKeyboard,
    faGears,
    faEye,
    faEyeSlash,
    faThumbsDown,
    faThumbsUp,
    faUserClock,
    faTimes,
     faBarChart, faRepeat, faUserLock, faRefresh, faUndo, faMap, faLocationDot, faBars, faVolumeHigh, faSliders, faVolumeXmark, faEllipsisVertical, faEllipsis, faRectangleList, faScaleBalanced, faCookie, faFileLines, faHandshakeAngle, faStar, faFolderOpen, faBook, faFileInvoiceDollar, faUserNinja, faCheck, faCheckCircle, faWindowClose, faUser, faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faKeyboard, faCommentDots, faAngleDoubleRight, faLineChart, faCode, faMagic
} from "@fortawesome/free-solid-svg-icons";
import {
    faInfinity,
    faBrain,
    faCodeBranch,
    faMobileScreenButton,
    faImagePolaroidUser,
    faHeadphones,
    faCommentText,
    faMessage,
    faHeart as fadHeart,
    faGift,
    faMoneyBill,
    faGifts,
    faLightbulb,
    faVideo,
    faGrid2,
    faCameraRetro,
    faBrainCircuit,
    faTicket,
    faPersonHiking,
    faWavePulse,
    faTrophyStar,
    faFire,
    faCauldron, faEarthAmericas, faMapLocationDot,
    faClipboardListCheck,
    faCircleXmark,
    faCameraRotate,
    faMicrophone,

} from '@fortawesome/pro-duotone-svg-icons';

import { faCircleSmall as farCircleSmall } from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faHeadphones,
    faFire,
    faPersonHiking,
    faCircleXmark,
    faThumbsDown,
    faThumbsUp,
    faCameraRetro,
    faCameraRotate,
    faWavePulse,
    farCircleSmall,
    faClipboardListCheck,
    faLightbulb,
    faMobileScreenButton,
    faGift,
    faBrainCircuit,
    faTicket,
    faCommentText,
    faUserClock,
    faMoneyBill,
    faGrid2,
    faGifts,
    faImagePolaroidUser,
    faCauldron,
    faEarthAmericas,
    faMessage,
    fadHeart,
    faTrophyStar,
    faMapLocationDot,
    faComment,
    faEdit,
    faCheck,
    faCheckCircle,
    faPlus,
    faEye,
    faEyeSlash,
    faGears,
    faUserLock,
    faPencil,
    faListCheck,
    faDownload,
    faPhone,
    faChartLine,
    faTrashAlt,
    faFileLines,
    faChevronUp,
    faPlayCircle,
    faStopCircle,
    faHeart,
    faCopy,
    faStar,
    faEnvelope,
    faTimes,
    faPaperPlane,
    faVideo,
    faMap,
    faLocationDot,
    faRefresh,
    faUndo,
    faInfinity,
    faSliders,
    faCookie,
    faRepeat,
    faCog,
    faBars,
    faEllipsisVertical,
    faEllipsis,
    faBarChart,
    faWindowClose,
    faScaleBalanced,
    faChevronRight,
    faChevronLeft,
    faKeyboard,
    faCommentDots,
    faAngleDoubleRight,
    faVolumeHigh,
    faVolumeXmark,
    faMicrophone,
    faLineChart,
    faCode,
    faMagic,
    faCheck,
    faCheckCircle,
    faMessage,
    faUser,
    faFolderOpen,
    faCodeBranch,
    faRectangleList,
    faBrain,
    faBook,
    faUserNinja,
    faFileInvoiceDollar,
    faHandshakeAngle,
    faChevronDown,
    fas
);






// const wb = require("./registerServiceWorker").wb;


import { IonicVue } from '@ionic/vue';
import { Storage } from '@ionic/storage';


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
// import './services/store.js';
// const store = require('./services/store.js');

import { createStore } from 'vuex'

const ionicstore = new Storage();
ionicstore.create();

export const globalErrorHandler = (error, vm, info, userUID) => {
     // send exception to google 
     window.gtag('event', 'exception', {
        'description': error,
        'fatal': false
    });

        // Send error to Google Analytics or another error tracking service
        window.gtag('event', 'error', {
            'event_category': 'Vue Error',
            'event_label': error.message,
            'user_uid': userUID, // Include userUID in event data
            'non_interaction': true
          });
    
    // Optionally, log the error to the console for debugging
    console.error('Global error handler:', error, info);
  };

  const vuex_avatar = require('./vuex/vuex_avatar.js');
  const vuex_billing = require('./vuex/vuex_billing.js');
  const vuex_actions = require('./vuex/vuex_actions.js');
  const vuex_purchase_tokens = require('./vuex/vuex_purchase_tokens.js');
const vuex_speaking_listening = require('./vuex/vuex_speaking_listening.js');
const vuex_bluetooth_controller = require('./vuex/vuex_bluetooth_controller.js');
const vuex_texting = require('./vuex/vuex_texting.js');
const vuex_listeningstreams = require('./vuex/vuex_listeningstreams.js');
const vuex_camera = require('./vuex/vuex_camera.js');
const vuex_video = require('./vuex/vuex_video.js');
const vuex_intro = require('./vuex/vuex_intro.js');
const vuex_subscriptions_and_limits = require('./vuex/vuex_subscriptions_and_limits.js');
const vuex_walkthrough = require('./vuex/vuex_walkthrough.js');
const vuex_votes = require('./vuex/vuex_votes.js');
const vuex_reviews = require('./vuex/vuex_reviews.js');
const vuex_store = require('./vuex/vuex_store.js');
const vuex_quiz = require('./vuex/vuex_quiz.js');
const vuex_top_actionbar = require('./vuex/vuex_top_actionbar.js');
const vuex_map = require('./vuex/vuex_map.js');
const vuex_system = require('./vuex/vuex_system.js');
const vuex_permissions = require('./vuex/vuex_permissions.js');
const vuex_memberships_upgrades = require('./vuex/vuex_memberships_upgrades.js');
const vuex_user_preferences = require('./vuex/vuex_user_preferences.js');
const vuex_chart = require('./vuex/vuex_chart.js');
const vuex_logging = require('./vuex/vuex_logging.js');
// Create a new store instance.
const store = createStore({
    state() {
        return {
            ...vuex_purchase_tokens.state,
            ...vuex_bluetooth_controller.state,
            // system
            ...vuex_system.state,
            // speaking listening
            ...vuex_speaking_listening.state,

            //texting
            ...vuex_texting.state,

            // listening streams
            ...vuex_listeningstreams.state,

            // avatar
            ...vuex_avatar.state,

            ...vuex_billing.state,
            // actions
            ...vuex_actions.state,

            // camera
            ...vuex_camera.state,

            // video
            ...vuex_video.state,

            // intro 
            ...vuex_intro.state,

            // subscriptions and limits
            ...vuex_subscriptions_and_limits.state,

            // walkthrough
            ...vuex_walkthrough.state,

            // reviews
            ...vuex_reviews.state,

            //downvotes and upvotes
            ...vuex_votes.state,

            // store
            ...vuex_store.state,

            // quiz
            ...vuex_quiz.state,

            // chart
            ...vuex_chart.state,

            // top actionbar
            ...vuex_top_actionbar.state,

            // map
            ...vuex_map.state,

            //permissions
            ...vuex_permissions.state,

            // memberships upgrades
            ...vuex_memberships_upgrades.state,

            // user preferences
            ...vuex_user_preferences.state,

            // logging
            ...vuex_logging.state,

            article: {
                keywords: '',
                meta_description: '',
                meta_keywords: '',
                article: '',
                url_slug: '',
                tags: '',
            },
            // brilliance 
            goDark: false,
            showExperienceSelector: localStorage.getItem('showExperienceSelector')?localStorage.getItem('showExperienceSelector')==='true':false,
            continueBrilliance: (localStorage.getItem('continueBrilliance') != null) ? localStorage.getItem('continueBrilliance') : 'continue',
            pauseBrilliance: (localStorage.getItem('pauseBrilliance') != null) ? localStorage.getItem('pauseBrilliance') : 'pause',
            //probably not used
            audioToVisualize: null,
            //operations 
            serviceWorkerCacheReady: false,
            hideCloseButtonAvatarSelector: false,
            activeScheduling: false,
            // photo upload
            showImageUploader: false,
            // views
            hideMapInitial: localStorage.getItem('hideMapInitial') != undefined ? localStorage.getItem('hideMapInitial') : true,
            showHearts: false,
            // snackbars
            snackbars: [],




            testingAccount: false,

            demoModeOnly: (process.env.NODE_ENV === 'development') ? false : false, // true
            canInstallFromButton: false,
            appIsInstalling: false,
            appIsInstalled: false,


            // drawing
            drawingJSON: null,
            drawingJSONToLoad: null,
            newUserDetected: false,
            loginDetected: false,
            showLargeImage: false,
            largeImageURL: null,
            showFloatingImage: false,
            FloatingImageURLs: null,
            floatingImageIsLarge: [false, false, false, false, false, false, false, false, false],
            buttonPressVibrateDuration: 50,
            // VIEWS VISIBILITY
            showCalls: false, // automated calls page
            showAudioView: false,
            showMarketingView: false,
            showChartView: false,
            showChatHistory: false,
            showMap: false,
            showNotes: false,
            showToDoList: false, // false
            showDrawingPad: false,
            showLoginPage: true,
            showPreloaderGlobal: true, //(process.env.NODE_ENV==='development' && !process.env.TEST_MODE)?false:true, //  true
            showPhone: true, //  true
            showPhoneOnStartup: (localStorage.getItem('showPhoneOnStartup') === 'true'), //  true
            showFAQ: false,
            showPermissionsScreen: false,
            showSignup: false,
            showImage: false,
            showAvatarSelector: false,
            showMainEntryLoader: true,
            showPasswordReset: false,
            showUserLogin: false,
            showMainApplication: false, // false
            showGiftSelector: false,
            showHomepage: true, // true
            showStripeSubscription: false,
            showLogs: false,
            showCodeView: false,
            ShowMainMenu: false,
            ShowAvatarSettings: false,
            showAppFeatures: false,
            showAIVitarSettingsMenu: false,
            locationInterval: null,

            /*>>>>>>>>>>>>>>>>>>>>>> still processing <<<<<<<<<<<<<<<<*/
            /*>>>>>>>>>>>>>>>>>>>>>> still processing <<<<<<<<<<<<<<<<*/
            /*>>>>>>>>>>>>>>>>>>>>>> still processing <<<<<<<<<<<<<<<<*/

            noIntroPointerEvents: false,
            xCord: 0,
            yCord: 0,
            yMargin: 0,

            aivitarX: false,
            showFloatingImage: true,
            //listening

            showAssistantTools: false,

            initialLaunch: false,
            conversationWatcher: 0,
            imageURL: null,
            responseType: 'VERBAL',

            //used only for codeview currently

            firstChatThisRound: true,

            logs: '',
            todos: (localStorage.getItem('todolist')) ? JSON.parse(localStorage.getItem('todolist')) : [],
            notes: (localStorage.getItem('notes')) ? JSON.parse(localStorage.getItem('notes')) : [],

            featureEnabledContinuous: true,
            featureEnabledBrilliance: false,
            featureEnabledImageUploade: false,
            featureEnabledCamera: false,
            featureEnabledVideo: false,
            featureEnabledGemini: false,
            featureEnabledTextToSpeech: (localStorage.getItem('featureEnabledTextToSpeech'))?localStorage.getItem('featureEnabledTextToSpeech')=='true':true,
            featureEnabledSpeechToText: false,
            featureEnabledDialIn: false,
            featureEnabledCharts: false,
            featureEnabledCode: false,
            featureEnabledResponses: false,
            cameraPermissionsSender: null,

            newAvatarSelector: 0,
            loginSelector:-1,

           showImmersiveIntro: false,
           showQuickIntro: false,
        }
    },
    getters: {
        key(state) {
            return state.key;
        },
    },
    mutations: {
        updateArticle(state, payload) {
            state.article = payload;
        },
        updateShowFloatingImage(state, payload) {
            state.showFloatingImage = payload;
        },

        updateTestingAccount(state, payload) {
            state.testingAccount = payload;
            localStorage.setItem('testingAccount', payload);
        },
        updateLargeImageURL(state, payload) {
            state.largeImageURL = payload;
        },
        updateShowLargeImage(state, payload) {
            state.showLargeImage = payload;
        },
        

        updateFloatingImageURLs(state, payload) {
            state.FloatingImageURLs = payload;
        },
        toggleFloatingImageSize(state, index){
            var setto = !state.floatingImageIsLarge[index];
            state.floatingImageIsLarge = [false, false, false, false, false, false, false, false, false];
            state.floatingImageIsLarge[index] = setto;
        },
        updateShowFloatingImage(state, payload) {
            state.showFloatingImage = payload;
            if(payload === false){
                state.floatingImageIsLarge = [false, false, false, false, false, false, false, false, false];
                state.FloatingImageURLs = null;
            }

        },
        
        updateShowPreloaderGlobal(state, payload) {
            state.showPreloaderGlobal = payload;
        },
        updateHideCloseButtonAvatarSelector(state, payload) {
            state.hideCloseButtonAvatarSelector = payload;
        },
        updateShowQuickIntro(state, payload) {
            state.showQuickIntro = payload;
        },
        updateShowImmersiveIntro(state, payload) {
            state.showImmersiveIntro = payload;
        },

        updateShowExperienceSelector(state, payload) {
            state.showExperienceSelector = payload;
            localStorage.setItem('showExperienceSelector', payload);
        },
        updateNoIntroPointerEvents(state, payload) {
            state.noIntroPointerEvents = payload;
        }, 
        updateSlideSelector(state, payload) {
            state[payload.slideSelector] = payload.slideIndex;
        },
        // system
        ...vuex_system.mutations,

        // speaking listening
        ...vuex_speaking_listening.mutations,

        //texting
        ...vuex_texting.mutations,

        // listening streams
        ...vuex_listeningstreams.mutations,

        // avatar
        ...vuex_avatar.mutations,

        ...vuex_billing.mutations,

        // actions
        ...vuex_actions.mutations,
        
        ...vuex_bluetooth_controller.mutations,
        // camera
        ...vuex_camera.mutations,

        //video 
        ...vuex_video.mutations,

        // intro 
        ...vuex_intro.mutations,

        // subscriptions and limits
        ...vuex_subscriptions_and_limits.mutations,

        // walkthrough
        ...vuex_walkthrough.mutations,

        // reviews
        ...vuex_reviews.mutations,

        //downvotes and upvotes
        ...vuex_votes.mutations,

        // store
        ...vuex_store.mutations,

        // quiz
        ...vuex_quiz.mutations,

        // chart
        ...vuex_chart.mutations,

        // top actionbar
        ...vuex_top_actionbar.mutations,

        // map
        ...vuex_map.mutations,

        ...vuex_purchase_tokens.mutations,

        //permissions
        ...vuex_permissions.mutations,

        // memberships upgrades
        ...vuex_memberships_upgrades.mutations,

        // user preferences
        ...vuex_user_preferences.mutations,

        // logging
        ...vuex_logging.mutations,

        setDrawingJSON(state, json) {
            state.drawingJSON = json;
        },



        // avatar 

        autoBlink(state) {
            state.blinking = true;
            setTimeout(() => {
                state.blinking = false;
            }, 100);

            setTimeout(() => {
                this.commit('blink');
            }, 100);
        },
        blink(state) {
            state.blinking = true;
            setTimeout(() => {
                state.blinking = false;
            }, 400);
        },

        // todo 
        addTodo(state, todo) {
            state.todos.push(todo);
            localStorage.setItem('todolist', JSON.stringify(state.todos));
            this.commit('showSnackbar', {
                color: 'royalblue',
                text: 'TO DO ADDED!',
                textColor: 'white',
            });
        },






        updateShowAppFeatures(state, payload) {
            state.showAppFeatures = payload;
        },

        updateFirstChatThisRound(state, payload) {
            state.firstChatThisRound = payload;
        },










        updateHideMapInitial(state, payload) {
            state.hideMapInitial = payload;
            localStorage.setItem('hideMapInitial', payload);
        },

        updateShowMainEntryLoader(state, payload) {
            state.showMainEntryLoader = payload;
        },

        async updateShowLocationPermissionsScreen(state, payload) {
           
            state.showLocationPermissionsScreen = payload;
        },
        updateShowAssistantTools(state, payload) {
            state.showAssistantTools = payload;
        },

        voiceOn(state) {

            state.useAudio = true;
            localStorage.setItem('useAudio', true);
        },
        voiceOff(state){
            state.useAudio = false;
            localStorage.setItem('useAudio', false);
        },
        updateSayIntroHello(state, payload) {
            state.sayIntroHello = payload;
        },
        updateShowWalkthrough(state, payload) {
            state.showWalkThrough = payload;
        },
        updateStartBlur(state, payload) {
            state.startBlur = payload;
        },
        updateSpeakToProcessContinuation(state, payload) {
            state.speakToProcessContinuation = payload;
        },
        updateProfileCompletedAlmost(state, payload) {
            state.profileCompletedAlmost = payload;
        },
        updateUseActivationWord(state, payload) {
            state.useActivationWord = payload;
            localStorage.setItem('useActivationWord', payload);
        },
        updateOnDeviceTextToSpeech(state, payload) {

            state.onDeviceTextToSpeech = payload;
            localStorage.setItem('onDeviceTextToSpeech', payload);
        },
        //   billingCurrentItems: [],
        //   billingAllHistoricalItems:[],
        updateGoDark(state, payload) {
            state.goDark = payload;
        },
        updateContinueBrilliance(state, payload) {
            state.continueBrilliance = payload;
            localStorage.setItem('continueBrilliance', payload);
        },
        updatePauseBrilliance(state, payload) {
            state.pauseBrilliance = payload;
            localStorage.setItem('pauseBrilliance', payload);
        },
        updateBrillianceMode(state, payload) {
            state.brillianceMode = payload;
            localStorage.setItem('brillianceMode', payload);
        },
        updateOnDeviceAudioRecording(state, payload) {
            state.onDeviceAudioRecording = payload;
            localStorage.setItem('onDeviceAudioRecording', payload);
        },
        updateBillingCurrentItems(state, payload) {
            state.billingCurrentItems = payload;
        },
        updateBillingAllHistoricalItems(state, payload) {
            state.billingAllHistoricalItems = payload;
        },
        updateNextPackage(state, payload) {
            state.nextPackage = payload;
        },
        updateShowLimitsReachedPage(state, payload) {
            console.log('showing limit reached page');
            state.showLimitsReachedPage = payload;
            localStorage.setItem('showLimitsReachedPage', payload);
        },
        updateShowGiftSelector(state, payload) {
            state.showGiftSelector = payload;
        },
        updateShowHearts(state, payload) {
            state.showHearts = payload;
        },
        updateLoginDetected(state, payload) {
            state.loginDetected = payload;
        },
        updateNewUserDetected(state, payload) {
            state.newUserDetected = payload;
        },
        updateBuildNumber(state, payload) {
            state.buildNumber = payload;
        },


        updateshowMarketingView(state, payload) {
            state.showMarketingView = payload;
        },

        updateShowAudioView(state, payload) {
            state.showAudioView = payload;
        },
        updateAudioToVisualize(state, payload) {
            state.audioToVisualize = payload;
        },
        updateShowDrawingPad(state, payload) {
            state.showDrawingPad = payload;
        },
        updateSimpleResponses(state, payload) {
            state.simpleResponses = payload;
            localStorage.setItem('simpleResponses', JSON.stringify(state.simpleResponses));
        },
        updateSelectedAvatarLanguage(state, payload) {
            state.selectedAvatarLanguage = payload;
        },
        updateSelectedAvatarVoiceRate(state, payload) {
            state.selectedAvatarVoiceRate = payload;
        },

        updateSelectedAvatarVoice(state, payload) {
            state.selectedAvatarVoice = payload;
        },

        updateInstalledVersion(state) {
            state.installedVersion = state.appVersion;
            localStorage.setItem('installedVersion', state.installedVersion);
        },
        updateShowLoginPage(state, payload) {
            state.showSignup = true;
            state.showHomepage = false;
            state.showLoginPage = payload;
            state.showUserLoginBlue = false;
        },
        updateShowHomepage(state) {
            state.showSignup = false;
            state.showHomepage = true;
            state.showLoginPage = false;
        },
        updateAppIsInstalled(state, payload) {
            state.appIsInstalled = payload;
        },
        updateAppIsInstalling(state, payload) {
            state.appIsInstalling = payload;
        },
        updateCanInstallFromButton(state, payload) {
            state.canInstallFromButton = payload;
        },
        updateActiveScheduling(state, payload) {
            state.activeScheduling = payload;
        },
        updateShowCalls(state, payload) {
            state.showCalls = payload;
        },
        updateShowAIVitarSettingsMenu(state, payload) {
            state.showAIVitarSettingsMenu = payload;
        },
        updateServiceWorkerCacheReady(state, payload) {
            state.serviceWorkerCacheReady = payload;
            //only storing in the storage so i can easily see not used for anything else
            localStorage.setItem('cacheReady', payload);
        },
        updateOnlyStorageCacheReady() {
            localStorage.setItem('cacheReady', 'no data');
        },

        updateShowPhoneOnStartup(state, value) {
            state.showPhoneOnStartup = value;
            localStorage.setItem('showPhoneOnStartup', value === true || value === 'true');
        },
        updateShowPhone(state, value) {
            state.showPhone = value;
            // localStorage.setItem('showPhone', `"${value}"`);
        },

        updateShowFAQ(state, value) {
            state.showFAQ = value;

        },



        updateTodo(state, { index, todo }) {
            state.todos.splice(index, 1, todo);
            localStorage.setItem('todolist', JSON.stringify(state.todos));
        },
        deleteTodo(state, index) {
            state.todos.splice(index, 1);
            localStorage.setItem('todolist', JSON.stringify(state.todos));
        },
        toggleCompleted(state, index) {
            state.todos[index].completed = !state.todos[index].completed;
            setTimeout(() => {
                localStorage.setItem('todolist', JSON.stringify(state.todos));
            }, 50);
        },
        //   notes
        addNote(state, note) {
            state.notes.push(note);
            localStorage.setItem('notes', JSON.stringify(state.notes));
            this.commit('showSnackbar', {
                color: 'royalblue',
                text: 'SAVED NOTE!',
                textColor: 'white',
            });


        },
        deleteNote(state, { index }) {
            state.notes.splice(index, 1);
            localStorage.setItem('notes', JSON.stringify(state.notes));
            // make all notes not editing
            let notes = state.notes;
            notes.forEach(note => {
                note.editing = false;
                note.show = false;
            });
            state.notes = notes;
            // localStorage.setItem('notes', JSON.stringify(this.$state.notes));


            this.commit('showSnackbar', {
                color: 'royalblue',
                text: 'Deleted NOTE!',
                textColor: 'white',
            });
        },
        editNote(state, index) {
            state.notes[index].editing = !state.notes[index].editing;
        },
        saveNote(state, { index, note }) {
            state.notes.splice(index, 1, note);
            localStorage.setItem('notes', JSON.stringify(state.notes));

            this.commit('showSnackbar', {
                color: 'royalblue',
                text: 'SAVED NOTE!',
                textColor: 'white',
            });

            setTimeout(() => {
                this.commit('toggleShowNote', index);
                this.commit('editNote', index);
            }, 200);
        },
        toggleShowNote(state, index) {
            console.log('toggleShowNote', index);
            setTimeout(() => {
                state.notes[index].show = !state.notes[index].show;

                setTimeout(() => {
                    if (state.notes[index].show === false) {
                        state.notes[index].editing = false;
                    }
                }, 200);
            }, 50);

        },
        closeNote(state, index) {
            console.log('close note', index);
            setTimeout(() => {
                state.notes[index].show = false;

                setTimeout(() => {
                    if (state.notes[index].show === false) {
                        state.notes[index].editing = false;
                    }
                }, 200);
            }, 50);
        },
        showSnackbarWarning(state, payload) {
            this.commit('showSnackbar', {
                color: '#ffed7a',
                text: payload,
                textColor: 'black'
            });
        },
        showSnackbarAdd(state, payload) {
            this.commit('showSnackbar', {
                color: 'lime',
                text: payload,
                textColor: 'black'
            });
        },

        showSnackbarInfo(state, payload) {
            this.commit('showSnackbar', {
                color: 'royalblue',
                text: payload,
                textColor: 'white'
            });
        },
        showSnackbar(state, payload) {
            setTimeout(() => {
                state.snackbars.push({
                    visible: true,
                    color: payload.color,
                    text: payload.text,
                    textColor: payload.textColor,
                });
            }, 800);
        },
        hideSnackbar(state) {
            state.snackbars.shift();
        },
        // fps: 120, // used to control the speed of the animation
        // timeSoundWaitingForAnimationToComplete: 0,
        // timeAnimationWaitingForSoundToComplete: 0,
        clearTextQuestion(state) {
            state.textQuestion = '';
        },

        updateShowNotes(state, payload) {
            state.showNotes = payload;
        },

        updateShowToDoList(state, payload) {
            state.showToDoList = payload;
        },

        updateFloatingText(state, payload) {
            state.floatingText = payload;
        },
        updateFloatingTextAI(state, payload) {
            state.floatingTextAI = payload;
        },

        updateShowMap(state, payload) {
            state.showMap = payload;
        },

        updateShowPasswordReset(state, payload) {
            state.showPasswordReset = payload;
        },

        updateShowAvatarSelector(state, payload) {
            state.showAvatarSelector = payload;
        },

        updateUseMemories(state, payload) {
            console.log('updating memories', payload)
            state.useMemories = payload;
            localStorage.setItem('useMemories', payload);
        },

        updateXYCord(state, payload) {
            state.xCord = payload.x;
            state.yCord = payload.y;
            state.yMargin = payload.yMargin;
        },

        removeDemoModeOnly(state) {
            state.demoModeOnly = false;
        },

        updateShowUserLogin(state, payload) {
            state.showUserLogin = payload;
        },

        updateShowMainApplication(state) {
            state.showMainApplication = true;
        },
        userHasSecurityToken(state) {
            state.showSignup = true;
            state.showHomepage = false;
            state.demoModeOnly = false;
        },

        updateIsListening(state, payload) {
            state.isListening = payload;
        },
        updateUser(state, payload) {
            try {

                // dynamically change state[key]
                state.key = payload.value;
            }
            catch (error) {
                console.log('error in update user state', error);
            }
        },

        updateTextOnlyMode(state, payload) {
            state.textOnlyMode = payload;
            localStorage.setItem('textOnlyMode', payload);
        },
        enableTextOnlyMode(state) {
            state.textOnlyMode = true;
            localStorage.setItem('textOnlyMode', true);
        },
        disableTextOnlyMode(state) {
            state.textOnlyMode = false;
            localStorage.setItem('textOnlyMode', false);
        },

        updatePerson(state, payload) {
            state.person = payload;
        },

        enableCodeResponses(state) {
            state.enableCodeResponses = true;
            localStorage.setItem('enableCodeRstate.continuoesponses', true);
        },
        disableCodeResponses(state) {
            state.enableCodeResponses = false;
            localStorage.setItem('enableCodeResponses', false);
        },
        closeCodeView(state) {
            state.showCodeView = false;
        },
        openCodeView(state) {
            state.showCodeView = true;
        },

        enableLogs(state) {
            state.showLogs = true;
        },
        disableLogs(state) {
            state.showLogs = false;
        },
        mainMenuOpen(state) {
            state.ShowMainMenu = true;
        },
        mainMenuClose(state) {
            state.ShowMainMenu = false;
        },
        updateShowMainMenu(state, payload) {
            state.ShowMainMenu = payload;
        },
        updateShowAvatarSettings(state, payload) {
            state.ShowAvatarSettings = payload;
        },

    },

    //end of mutations
    actions: {
        ...vuex_billing.actions,
        ...vuex_actions.actions,

        ...vuex_votes.actions,
                addIncomingImage(context, imageURL){
        
            const date = new Date();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            const dateString = month + '-' + day + '-' + year;

            // get current time in 12 hours format
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'pm' : 'am';
            const timeString = (hours > 12 ? hours - 12 : hours) + ':' + minutes + ' ' + ampm;

           
                console.log('adding to message');
                /** Update Chat Message */
                // get content of last message
                console.log('current message list', context.state.chatList);
                // const lastMessage = { ...context.state.chatList[context.state.chatList.length - 1] };

                // const lastMessageContent = lastMessage.content;
                // const message = lastMessageContent + ' <br /><br />' + payload.content;
                const message = '';

                const updatedChatMessage = {
                    //these are set incoming
                    role: 'assistant',
                    content: message,
                    images: [imageURL],
                    //set here
                    popupVisible: false,
                    date: dateString,
                    time: timeString,
                }
                // replace last message with updated message
                // const newMessage = updatedChatMessage;
                // remove last message
                // var newListFirst = [...context.state.chatList];

                // newListFirst = [...newListFirst, updatedChatMessage];
                context.state.chatList.push(updatedChatMessage);
                // context.state.chatList[context.state.chatList.length - 1] = updatedChatMessage;


            
            // if (payload.role === 'assistant') {
            //     context.state.firstChatThisRound = false;
            //     context.state.textViewWaitingForReplay = false;
            // }
            // localStorage.setItem('chatList', state.chatList);

            var convoNumber = context.state.conversationWatcher;
            context.state.conversationWatcher = convoNumber + 1;
        },
        buzzPhone(context){
            if ('vibrate' in navigator) {
              navigator.vibrate(context.state.buttonPressVibrateDuration);
            }
          },
          async getProfile(context) {
            console.log("[checkProfileComplete]");
            if (!localStorage.getItem("security")) {
              return;
            }
            let data = {
              token: localStorage.getItem("security"),
              stage: "checkProfileComplete",
              billingPlatform: context.state.billingPlatform,
            };
            var config = {
              method: "post",
              url: `${context.state.systemRoute}/profile`,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: data,
            };
    
            let response = await axios(config);
            
            console.log("appIntro 2 profile response", response);
    
            if (response && response.data && response.data) {
                if(response.data.tester === true){
                    context.commit('updateTestingAccount', true);
                  }
                if(response.data.unlimited===true){
                    context.commit('updateProMembership', true);
                  }
              if (response.data.uid) {
                context.commit("updateUserUID", response.data.uid);
              }
              if (response.data.valid === false) {
                localStorage.removeItem("security");
                localStorage.clear();
                // reload page
                location.reload();
                return;
              }
              if(response.data.customer_gender){
                context.commit("updateCustomerGender", response.data.customer_gender);
              }
    
              if(response.data.selected_gender){
                context.commit("updateCompanionPreference", response.data.selected_gender);
              }
              if(response.data.selected_personality){
                context.commit("updateCustomerSelectedProfile", response.data.selected_personality);
              }
    
              if(response.data.selected_voice){
                context.commit("updateCustomerSelectedVoice", response.data.selected_voice);
              }
              context.commit("updateAppExpectations", {
                romantic: response.data.romantic || false,
                  tool: response.data.tool || false,
                  assistant:  response.data.assistant || false,
                  code:  response.data.code || false,
                  information:  response.data.information || false 
              });
              if (response.data.avatar) {
                console.log("setting avatar");
                context.commit("updateAvatar", response.data.avatar);
  
                let galleryAvatar;
                for (let i = 0; i < context.state.avatarList.length; i++) {
                  if (context.state.avatarList[i].name.toLowerCase() === response.data.avatar.toLowerCase() ) {
                    galleryAvatar = context.state.avatarList[i];
                      break;
                  }
                }
                if (galleryAvatar) {
                    context.commit('updateSelectedGalleryAvatar', galleryAvatar);
                }
              }
              if (response.data.stepsCompleted < 7) {
                
                context.commit("updateProfileStep", response.data.stepsCompleted);
                if (response.data.stepsCompleted >= 1) {
                  this.isReturningToCompleteProfile = true;
                  this.introSlides = [];
                  this.showBegin = true;
                }
                //  context.commit('updateProfileStep', 2);
                // context.commit('updateProfileStep', 6);
                // this.showProfileButton = true;
                // this.processProfileStep();
              } else {
                // profile is completed
    
                context.commit("updateProfileCompleted", true);
                context.commit("updateProfileCompletedAlmost", true);
              }
    
              setTimeout(() => {
                context.commit("updateShowMainEntryLoader", false);
              }, 1500);
            }
          },

          async checkForPro(context) {
            console.log("[checkForPro]");
            if (!localStorage.getItem("security")) {
              return;
            }
            let data = {
              token: localStorage.getItem("security"),
             
            };
            var config = {
              method: "post",
              url: `${context.state.systemRoute}/checkForPro${context.state.testingAccount === true ? "Dev" : ""}`,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: data,
            };
    
            let response = await axios(config);
            
            console.log("checkForPro Response", response);
            if(response.data){
                console.log('>>>> check for pro', response.data);
                var isPro = response.data.isPro;
               context.commit('updateProMembership', isPro);

            }
          
            
          },
          crazyEggShowTutorialSurvey(){
            if(window && window.CE_API && window.CE2){
                (window.CE_API || (window.CE_API=[])).push(function(){
                    if(window.CE2){
                        window.CE2.showSurvey("d7c47118-4bff-4816-9414-6e25cf4e15c4");
                    }
                    });
                }
          },
          crazyEggAddAccount(){
            if(window && window.CE_API && window.CE2){
                (window.CE_API || (window.CE_API=[])).push(function(){
                    if(window.CE2){
                        window.CE2.identify(localStorage.getItem('userUID'));
                    }
                    });
                }
          },
          crazyEggShowIntroSurvey(){
            if(window && window.CE_API && window.CE2){
                (window.CE_API || (window.CE_API=[])).push(function(){
                    if(window.CE2){
                        window.CE2.showSurvey("9894ec10-8452-4766-b5f1-49eb15d7d0e0");
                    }
                    });
                }
          },
          crazyEggShowIntroSurveyQUICK(){
            if(window && window.CE_API && window.CE2){
                (window.CE_API || (window.CE_API=[])).push(function(){
                    if(window.CE2){
                        window.CE2.showSurvey("e22f031e-a14b-4571-b4d8-58aca8d25ace");
                    }
                    });
                }
          },
        crazyEggUserLoggedIn(){
            if(window && window.CE_API && window.CE2){
                (window.CE_API || (window.CE_API=[])).push(function(){
                    if(window.CE2){
                        window.CE2.set(1,'logged in');
                        window.CE2.set(2, localStorage.getItem('userUID')); 
                    }
                    });
                }
        },
        crazyEggStartRecording(){
            //start crazy egg recording 
            if(window && window.CE_API && window.CE2){
                (window.CE_API || (window.CE_API=[])).push(function(){
                    if(window.CE2){
                        window.CE2.startRecording();
                    }
                    });
                }

        },
        crazyEggStopRecording(){
            //start crazy egg recording 
            if(window && window.CE_API && window.CE2){
                (window.CE_API || (window.CE_API=[])).push(function(){
                    if(window.CE2){
                        window.CE2.stopRecording();
                    }
                    });
                }

        },
        updateFeatureEnabled(context, payload){
            try {
                context.commit('updateActivityLogs', `[updateFeatureEnabled] from ${payload.sender} Setting ${payload.feature} to ${payload.value}`);
            }
            catch(error){
                console.log('[updateFeatureEnabled] error in updateFeatureEnabled', error);
            }

            if(payload.feature === 'continuous'){
                context.state.featureEnabledContinuous = payload.value;
            }
            if(payload.feature === 'imageUploader'){
                context.state.featureEnabledImageUploade = payload.value;
            }
            if(payload.feature === 'camera'){
                context.state.featureEnabledCamera = payload.value;
            }
            if(payload.feature === 'video'){
                context.state.featureEnabledVideo = payload.value;
            }
            if(payload.feature === 'gemini'){
                context.state.featureEnabledGemini = payload.value;
            }
           
            if(payload.feature === 'textToSpeech'){
                if(payload.value === false){
                    //turning off the text to speech
                    context.state.featureEnabledTextToSpeech = payload.value;
                    localStorage.setItem('featureEnabledTextToSpeech', payload.value);
                } else {
                    //turning on the text to speech
                    context.state.featureEnabledTextToSpeech = payload.value;
                    localStorage.setItem('featureEnabledTextToSpeech', payload.value);

                }
            }
            if(payload.feature === 'speechToText'){
                context.state.featureEnabledSpeechToText = payload.value;
            }
            if(payload.feature === 'dialIn'){
                context.state.featureEnabledDialIn = payload.value;
            }
            if(payload.feature === 'charts'){
                context.state.featureEnabledCharts = payload.value;
            }
            if(payload.feature === 'code'){
                context.state.featureEnabledCode = payload.value;
            }
            if(payload.feature === 'responses'){
                context.state.featureEnabledResponses = payload.value;
            }
            if(payload.feature === 'brilliance'){
                context.state.featureEnabledBrilliance = payload.value;
            }
        },
       async checkFeatureEnabled(context, feature){
            var isEnabled=false;
            if(feature === 'continuous'){
                if(context.state.featureEnabledContinuous){
                    isEnabled=true;
                }
            } else if(feature === 'imageUploader'){
                if(context.state.featureEnabledImageUploade){
                    // isEnabled=true;
                    // context.commit('updateShowImageUploader', true)
                }
            } else if(feature === 'camera'){
                context.state.cameraPermissionsSender = 'camera';
                if(context.state.featureEnabledCamera){
                        isEnabled=true;
                        try {
                            const permissionStatus = await navigator.permissions.query({ name: 'camera' });
                      
                            switch (permissionStatus.state) {
                              case 'granted':
                                context.commit('updatePermissions', { permission: 'camera', value: true });
                                context.commit('updateShowCameraPermissionScreen', false);
                                context.commit('updateShowCamera', true);
                                break;
                              case 'denied':
                                context.commit('updatePermissions', { permission: 'camera', value: 'denied' });
                                context.commit('updateShowCameraPermissionScreen', true);
                                context.commit('updateShowCamera', false);
                                break;
                              case 'prompt':
                                context.commit('updatePermissions', { permission: 'camera', value: false });
                                context.commit('updateShowCameraPermissionScreen', true);
                                context.commit('updateShowCamera', false);
                                break;
                              default:
                                // Handle any other status if necessary
                                break;
                            }
                          } catch (error) {
                            console.error('Error checking camera permission:', error);
                          }
                }
            }
            else if(feature === 'video'){
                context.state.cameraPermissionsSender = 'video';
                if(context.state.featureEnabledVideo){
                    isEnabled=true;
                        try {
                            const permissionStatus = await navigator.permissions.query({ name: 'camera' });
                      
                            switch (permissionStatus.state) {
                              case 'granted':
                                context.commit('updatePermissions', { permission: 'camera', value: true });
                                context.commit('updateShowCameraPermissionScreen', false);
                                context.commit('updateShowVideo', true);
                                break;
                              case 'denied':
                                context.commit('updatePermissions', { permission: 'camera', value: 'denied' });
                                context.commit('updateShowCameraPermissionScreen', true);
                                break;
                              case 'prompt':
                                context.commit('updatePermissions', { permission: 'camera', value: false });
                                context.commit('updateShowCameraPermissionScreen', true);
                                break;
                              default:
                                // Handle any other status if necessary
                                break;
                            }
                          } catch (error) {
                            console.error('Error checking camera permission:', error);
                          }

                }
            }
            else if(feature === 'gemini'){
                if(context.state.featureEnabledGemini){
                    isEnabled=true;
                }
            }
            else if(feature === 'textToSpeech'){
                if(context.state.featureEnabledTextToSpeech){
                    isEnabled=true;
                    context.commit('updateOnDeviceTextToSpeech', !context.state.onDeviceTextToSpeech);
                }
            }
            else if(feature === 'speechToText'){
                if(context.state.featureEnabledSpeechToText){
                    isEnabled=true;
                   context.commit('updateOnDeviceAudioRecording', !context.state.onDeviceAudioRecording);
                }
            }
            else if(feature === 'dialIn'){
                if(context.state.featureEnabledDialIn){
                    isEnabled=true;
                    context.commit('updateShowPhoneOnStartup', !context.state.showPhoneOnStartup);
                }
            }
            else if(feature === 'charts'){
                if(context.state.featureEnabledCharts){
                    isEnabled=true;
                }
            }
            else if(feature === 'code'){
                if(context.state.featureEnabledCode){
                    isEnabled=true;
                }
            }
            else if(feature === 'responses'){
                if(context.state.featureEnabledResponses){
                    isEnabled=true;
                }
            }
            else if(feature === 'brilliance'){
                if(context.state.featureEnabledBrilliance){
                    isEnabled=true;
                }
            }
            if(!isEnabled){
                context.state.showUpgradeMembershipPage=true;
            }
            return isEnabled;

        },
        async saveProfileToDB(context, which='all'){
            return new Promise(async resolve => {
       
            let data = {};
            // if(which==='all'){
            
                data = {
                    token: localStorage.getItem("security"),
                    aivitar: (context.state.selectedGalleryAvatar && context.state.selectedGalleryAvatar.name)?context.state.selectedGalleryAvatar.name: null,
                    customerGender: context.state.customerGender,
                    customerExpectations: context.state.appExpectations,
                    customerPreference: context.state.companionPreference,
                    customerSelectedProfile: context.state.customerSelectedProfile,
                    customerSelectedVoice: context.state.customerSelectedVoice,
                };
            // }

            // if(which==='avatar'){
            //     data = {
            //         token: localStorage.getItem("security"),
            //         aivitar: context.state.selectedGalleryAvatar.name,
            //         customerGender: context.state.customerGender,
            //         customerPreference: context.state.companionPreference,
            //         customerSelectedProfile: context.state.customerSelectedProfile,
            //         customerSelectedVoice: context.state.customerSelectedVoice,
            //     };
            // }
            // if(which == 'voice'){
            //     data = {
            //         token: localStorage.getItem("security"),
            //         aivitar: context.state.selectedGalleryAvatar.name,
            //         customerGender: context.state.customerGender,
            //         customerPreference: context.state.companionPreference,
            //         customerSelectedProfile: context.state.customerSelectedProfile,
            //         customerSelectedVoice: context.state.customerSelectedVoice,
            //     };
            // }
            // if(which == 'profile'){
            //     data = {
            //         token: localStorage.getItem("security"),
            //         aivitar: context.state.selectedGalleryAvatar.name,
            //         customerGender: context.state.customerGender,
            //         customerPreference: context.state.companionPreference,
            //         customerSelectedProfile: context.state.customerSelectedProfile,
            //         customerSelectedVoice: context.state.customerSelectedVoice,
            //     }
            // }
                    
              var config = {
                method: "post",
                url:`${context.state.systemRoute}/setAvatar`,
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                data: data,
              };
      
              try {
                await axios(config);
                resolve();
              }
              catch (error) {
                console.log(error);
                resolve();
              }
      
            });
      
          },
        clearAvatarSounds(context) {
            context.state.avatarSounds = [];
            context.commit('updateResetMouthDefaults', true);
        },
        addChat(context, payload) {
            /*
                {
            }




            */
            const lastMessage = { ...context.state.chatList[context.state.chatList.length - 1] };
            var firstRound = false;
            if (lastMessage.role === 'user') {
                firstRound = true
            }
            var newListFirst;
            /**
             {
                content: //string
                popupVisible: //boolean
                date: //string
                time: //string
                images: array of strings
                role: //string (user or ai)
             */
            // get curent date in MM-DD-YYYY format
            const date = new Date();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            const dateString = month + '-' + day + '-' + year;

            // get current time in 12 hours format
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'pm' : 'am';
            const timeString = (hours > 12 ? hours - 12 : hours) + ':' + minutes + ' ' + ampm;

            if ((firstRound) || payload.role === 'user') {
                console.log('new message');
                /* New Message Entry */

                const message = payload.content;
                const chatMessage = {
                    //these are set incoming
                    role: payload.role,
                    content: message,
                    images: payload.images,

                    //set here
                    popupVisible: false,
                    date: dateString,
                    time: timeString,
                }

                // add it to the beginning of the list
                newListFirst = [...context.state.chatList];
                // add it to the beginning of the list
                newListFirst = [...newListFirst, chatMessage];
                // newListFirst.unshift(chatMessage);
                // 
                context.state.chatList = newListFirst;



            } else {
                console.log('adding to message');
                /** Update Chat Message */
                // get content of last message
                console.log('current message list', context.state.chatList);
                const lastMessage = { ...context.state.chatList[context.state.chatList.length - 1] };

                const lastMessageContent = lastMessage.content;
                const message = lastMessageContent + ' <br /><br />' + payload.content;
                const images = lastMessage.images;

                const updatedChatMessage = {
                    //these are set incoming
                    role: payload.role,
                    content: message,
                    images:[...images, ...payload.images],

                    //set here
                    popupVisible: false,
                    date: dateString,
                    time: timeString,
                }
                // replace last message with updated message
                // const newMessage = updatedChatMessage;
                // remove last message
                newListFirst = [...context.state.chatList];

                // newListFirst = [...newListFirst, updatedChatMessage];
                context.state.chatList[context.state.chatList.length - 1] = updatedChatMessage;


            }
            if (payload.role === 'assistant') {
                context.state.firstChatThisRound = false;
                context.state.textViewWaitingForReplay = false;
            }
            // localStorage.setItem('chatList', state.chatList);

            var convoNumber = context.state.conversationWatcher;
            context.state.conversationWatcher = convoNumber + 1;
        },
        async getQueuedOnDeviceSentences(context) {
            return new Promise((resolve) => {
                resolve(context.state.queuedOnDeviceTTSSentences);
            })
        },
        async getQueuedOnDeviceAnimations(context) {
            return new Promise((resolve) => {
                resolve(context.state.queuedOnDeviceTTSAnimations);
            })
        },
        async getQueuedCloudSentences(context) {
            return new Promise((resolve) => {
                resolve(context.state.queuedCloudTTSSentences);
            })
        },
        async gatherSubscriptionDetails(context) {
            return new Promise((resolve) => {
                var activeSubscriptionId;
                var activeSubscriptionPackage;
                var subscriptionType;

                try {
                    activeSubscriptionId = context.state.activeSubscriptionID;
                }
                catch (e) {
                    context.commit('updateActivityLogs', 'error in activeSubscriptionID');
                    activeSubscriptionId = 'nope3';
                }
                try {
                    activeSubscriptionPackage = context.state.activeSubscriptionPackage;
                }
                catch (e) {
                    context.commit('updateActivityLogs', 'error in activeSubscriptionPackage');
                    activeSubscriptionPackage = 'nope3';
                }
                try {
                    subscriptionType = context.state.subscriptionType;
                }
                catch (e) {
                    context.commit('updateActivityLogs', 'error in subscriptionType');
                    subscriptionType = 'nope2';
                }
                resolve({
                    activeSubscriptionId: activeSubscriptionId,
                    activeSubscriptionPackage: activeSubscriptionPackage,
                    subscriptionType: subscriptionType
                });
            });
        },
        async updateOnDeviceVoice(context) {
            return new Promise((resolve) => {

                var avatar = context.state.avatar;
                const synth = window.speechSynthesis;
                let voices = synth.getVoices();
                if (avatar === 'marcus') {
                    var maleUKVoice;
                    // find a UK male voice if available if not find male voice to use

                    for (var i = 0; i < voices.length; i++) {
                        if (voices[i].name === 'Google UK English Male') {
                            maleUKVoice = voices[i];
                            break;
                        }
                    }

                    // If a male UK voice isn't found, just select the first male voice.
                    if (!maleUKVoice) {
                        console.log('used second voice');
                        for (var a = 0; a < voices.length; a++) {
                            if (voices[a].name.toLowerCase().indexOf('female') < 0) {
                                maleUKVoice = voices[a];
                                break;
                            }
                        }
                    } else {
                        console.log('used first voice');
                    }
                    context.state.selectedAvatarVoice = maleUKVoice;

                }

                if (avatar === 'kurt') {
                    var maleVoice;
                    // find a UK male voice if available if not find male voice to use

                    for (var b = 0; b < voices.length; b++) {
                        if (voices[b].name === 'Google UK English Male') {
                            maleVoice = voices[b];
                            break;
                        }
                    }

                    // If a male UK voice isn't found, just select the first male voice.
                    if (!maleVoice) {
                        for (var c = 0; c < voices.length; c++) {
                            if (voices[c].name.toLowerCase().indexOf('female') < 0) {
                                maleVoice = voices[c];
                                break;
                            }
                        }
                    }
                    context.state.selectedAvatarVoice = maleVoice;

                }

                if (avatar === 'sarah') {
                    var femaleVoice;
                    // find a UK male voice if available if not find male voice to use

                    for (var d = 0; d < voices.length; d++) {
                        if (voices[d].name === 'Google UK English Female') {
                            femaleVoice = voices[d];
                            break;
                        }
                    }

                    // If a male UK voice isn't found, just select the first male voice.
                    if (!femaleVoice) {
                        for (var e = 0; e < voices.length; e++) {
                            if (voices[e].name.toLowerCase().indexOf('female') > 0) {
                                femaleVoice = voices[e];
                                break;
                            }
                        }
                    }
                    context.state.selectedAvatarVoice = femaleVoice;


                }

                if (avatar === 'mary') {
                    var femVoice;

                    for (var f = 0; f < voices.length; f++) {

                        if (voices[f].name.toLowerCase().indexOf('female') > 0) {
                            femVoice = voices[f];

                        }

                    }
                    //   }
                    context.state.selectedAvatarVoice = femVoice;


                }

                resolve();
                // selectedAvatarLanguage: null,
                // selectedAvatarVoice: null,
                // selectedAvatarVoiceRate: 1,



            });

        },
        async getMetadata(context) {
            return new Promise((resolve) => {
                const currentDate = new Date();
                var conversationList = context.state.conversationList;
                if (conversationList.length > 10) {
                    conversationList = conversationList.slice(0, 10);
                }
                let metadata = {

                    time: currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                    date: currentDate.toDateString(),
                    clothingColor: context.state.avatarClothingColor,
                    outfit: context.state.avatarClothing,
                    location: context.state.avatarLocation,
                    avatar: context.state.avatar,
                    scheduling: context.state.activeScheduling,
                    version: process.env.VUE_APP_VERSION,
                    long: context.state.locationLong,
                    lat: context.state.locationLat,
                    conversations: JSON.stringify(conversationList),
                    onDeviceSTT: context.state.onDeviceTextToSpeech,
                    quizMe: context.state.quizMe,
                    quizTopic: context.state.quizTopic,
                    quizConvo: JSON.stringify(context.state.gameShowConversation),
                    nextQuizQuestion: context.state.quizNextQuestion ? context.state.quizNextQuestion : null,
                };

                resolve(metadata);
            });
        },
        async getAvatar(context) {
            return new Promise((resolve) => {
                var avatar = context.state.avatar;
                resolve(avatar);
            });
        },

        closeAllViews(context) {
            context.commit('updateShowAIVitarSettingsMenu', false);
            context.commit("updateShowMainMenu", false);
            context.commit("updateShowAvatarSettings", false);
            context.commit("updateShowAudioView", false);
            context.commit("updateShowCalls", false);
            context.commit("updateShowNotes", false);
            context.commit("updateShowToDoList", false);
            context.commit("updateShowMap", false);
            context.commit("updateViewChatHistory", false);
            context.commit("updateUseAudio", false);
            context.commit("updateTextOnlyMode", false);
        },
        selectedAvatar(context) {

            var selectedAvatar = context.state.avatarList.find(item => item.name === context.state.avatar);
            return selectedAvatar

        },

        showViewUserSettings(context) {
            context.dispatch('closeAllViews');
            context.commit('updateShowMainMenu', true);
            context.commit('updateShowAvatarSettings', true);
        },
        
        async showViewMap(context) {
            if(context.state.permission_location === true || context.state.permission_location === 'true'){
                context.commit('updateShowMap', true);
                context.commit('updateHideMapInitial', false);
                context.dispatch('closeAllViews');
                context.commit('updateShowMap', true);
                context.commit('updateUseAudio', true);
                context.commit('voiceOn');
            } else {

                context.dispatch('locationPermissionChecker');
            }
        },
        async locationPermissionChecker(context){
                // if we dont have permissions because they were prevously denied
                if(context.state.permission_location === 'denied'){
                    // check once again for permissions before showing page
                    var haveLocationPermissions  =  await context.dispatch("requestLocationPermission");
                    if(!haveLocationPermissions){
                        //okay still dont have show permissions screen
                        context.commit('updateShowLocationPermissionsScreen', true);
                    } else {
                        // should have permissions lets go!
                        context.dispatch('showViewMap');
                    }
                } else {
                    // regular first time asking for permissions
                    context.commit('updateShowLocationPermissionsScreen', true);
                }
        },
        showViewText(context) {
            context.dispatch('closeAllViews');
            context.commit('updateViewChatHistory', true);
            context.commit('updateTextOnlyMode', true);
        },
        showViewVideo(context) {
            context.dispatch('closeAllViews');
            context.commit('updateUseAudio', true);
            context.commit('voiceOn');
        },
        async checkDailyLimit(context) {
            context.commit('updateActivityLogs', `[checkDailyLimit]`);
            if (!localStorage.getItem("security")) {
                return;
            }
            // if(context.state.profileCompleted === false){
            //     return;
            // }


            const qs = require('qs');
            var userToken = localStorage.getItem('security');
            const data = qs.stringify({
                token: userToken
            });
            const config = {
                method: 'post',
                url: `${context.state.systemRoute}/checkDailyLimit`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };


            axios(config)
                .then(async function (response) {
                    const checkDailyLimit = response.data;

                    console.log('checkDailyLimit', checkDailyLimit);
     
                    // show that we have reached the limit
                    var LimitReachedNow = checkDailyLimit.limitReached === true || checkDailyLimit.remainingCalls === 0 || checkDailyLimit.remainingCalls < 0;


                    // set the next package they should upgarade to
                    if (LimitReachedNow && checkDailyLimit.nextPackage) {
                        context.commit('updateNextPackage', checkDailyLimit.nextPackage);
                    }
                    if(LimitReachedNow){
                        context.commit('updateShowLimitsReached', true);
                    }
                })
                .catch(async function (error) {
                    context.commit('updateActivityLogsError', 'error in checkDailyLimit' + JSON.stringify(error));
                    console.error('Issue with checkDailyLimit', error);
                });

        },
        async upgradeToProMembershipMonthly(context, payload) {
            let { sale, sku } = payload;
            if(!sku){
                sku = 'pro_monthly';
            }
            if(!sale){
                sale = false;
            }
            if ('getDigitalGoodsService' in window) {

                context.commit('updateActivityLogs', 'Trying upgrade to pro membership');
                var service = await window.getDigitalGoodsService("https://play.google.com/billing");

                const details = await service.getDetails([sku]);
                // const promoSkuDetails = details.find(sku => sku.promotionType === 'INTRODUCTORY');

                

                var ResponseFromPurchase = JSON.stringify(details);
                
                // get offer id
                
               
                let paymentMethodData;


                // const item = details[0];
              

                if(sale===true){
                  
                    paymentMethodData = [{
                        supportedMethods: ['https://play.google.com/billing'],
                        data: {
                            sku: promoSkuDetails.sku,
                            type: 'subs',
                            // show email in payment sheet
                            emailRequired: true,
                        }
                    }]
                    
                } else {
                    paymentMethodData = [
                        {
                            supportedMethods: 'https://play.google.com/billing',
                            data: {
                                sku: sku, 
                                // type: 'subs',
                                // show email in payment sheet
                                emailRequired: true,
                            },
                        },
                    ];
                }


                const request = new PaymentRequest(paymentMethodData);

                const paymentResponse = await request.show();
                ResponseFromPurchase = JSON.stringify(paymentResponse);
                console.log(ResponseFromPurchase)

                var orderID = paymentResponse.details.orderId;
                // get purchase token and email
                const { purchaseToken } = paymentResponse.details;

                // get email from payment response
                // const { email } = paymentResponse.payerEmail;
                var paymentComplete;

                const qs = require('qs');
                var userToken = localStorage.getItem('security');
                const data = qs.stringify({
                    token: purchaseToken,
                    itemID: sku,
                    type: 'subscription',
                    userToken: userToken,
                    orderID: orderID
                    // paymentEmail: email
                });
                const config = {
                    method: 'post',
                    url: `${context.state.systemRoute}/upgradePro`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: data
                };
                var localContext = context;


                var response = await axios(config);
                const message = response.data;
                console.log('message', message);
                if (message.success === true) {
                    try {
                        window.fbq('track', 'Purchase', {currency: "USD", value: 24.99});
                    }
                    catch(error){
                        console.log('error in fbq', error);
                    }
                    try {
                        paymentComplete = await paymentResponse.complete('success');
                        context.dispatch('setSubscriptionToDB', sku);
                    }
                    catch(error){
                        paymentComplete = await paymentResponse.complete('fail');
                        localContext.state.showSnackbarWarning('There was an issue processing your transaction');
                        console.log(error);
                        console.log(paymentComplete)
                    }
                    try {
                        context.commit('showSnackbarInfo', 'Subscription Activated');
                        context.commit('updateShowUpgradeMembershipPage', false);
                        context.commit('updateProMembership', true);
                        context.dispatch('validateGooglePayment');
                        context.commit('updateShowHearts', true);
                        context.dispatch("checkAllHistoricalGooglePurchases");
                        context.commit('updateShowHearts', false);
                        context.dispatch('reportEvent', 'membership_upgraded_monthly');
                    }
                    catch(error){
                        context.dispatch('validateGooglePayment');
                        context.commit('showSnackbarInfo', 'Something went wrong, please try again');
                        context.commit('updateShowUpgradeMembershipPage', false);
                    }
                
                } else {
                    paymentComplete = await paymentResponse.complete('fail');
                    localContext.state.showSnackbarWarning(message.message);
                    context.commit('showSnackbarInfo', 'Something went wrong, please try again');
                    context.commit('updateShowUpgradeMembershipPage', false);
                }

            } else {
                context.commit('updateActivityLogs', 'no getDigitalGoodsService');
                context.commit('showSnackbarInfo', 'Something went wrong, please try again');
                context.commit('updateShowUpgradeMembershipPage', false);
            }


        },
        async upgradeToProMembership(context, payload) {
            if ('getDigitalGoodsService' in window) {

                
                var freeTrial = payload.freeTrial;
                var membership = payload.membership;
                
                context.commit('updateActivityLogs', 'Trying upgrade to pro membership');
                var service = await window.getDigitalGoodsService("https://play.google.com/billing");

                const details = await service.getDetails([membership]);

                var ResponseFromPurchase = JSON.stringify(details);

                // const item = details[0];
                const paymentMethodData = [
                    {
                        supportedMethods: 'https://play.google.com/billing',
                        data: {
                            sku: membership
                        },
                    },
                ];


                if(freeTrial === true){
                    paymentMethodData[0].data.offerId = 'free-trial';
                }

                const request = new PaymentRequest(paymentMethodData);
                const paymentResponse = await request.show();
                ResponseFromPurchase = JSON.stringify(paymentResponse);
                console.log(ResponseFromPurchase)

                const { purchaseToken } = paymentResponse.details;
                // verify paymentResponse
                var orderID = paymentResponse.details.orderId;
                var paymentComplete;
                context.commit('updateProMembership', true);
                const qs = require('qs');
                var userToken = localStorage.getItem('security');
                const data = qs.stringify({
                    token: purchaseToken,
                    itemID: membership,
                    type: 'subscription',
                    userToken: userToken,
                    orderID: orderID
                });
                const config = {
                    method: 'post',
                    url: `${context.state.systemRoute}/upgradePro`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: data
                };
                var localContext = context;


                var response = await axios(config);
                const message = response.data;
                console.log('message', message);
                if (message.success === true) {
                    try {
                        window.fbq('track', 'Purchase', {currency: "USD", value: 24.99});
                    }
                    catch(error){
                        console.log('error in fbq', error);
                    }
                    try {
                        paymentComplete = await paymentResponse.complete('success');
                        context.dispatch('setSubscriptionToDB', sku);
                    }
                    catch(error){
                        paymentComplete = await paymentResponse.complete('fail');
                        localContext.state.showSnackbarWarning('There was an issue processing your transaction');
                        console.log(error);
                        console.log(paymentComplete)
                    }
                    try {
                        context.commit('showSnackbarInfo', 'Subscription Activated');
                        context.commit('updateShowUpgradeMembershipPage', false);
                        context.commit('updateProMembership', true);
                        context.dispatch('validateGooglePayment');
                        context.commit('updateShowHearts', true);
                        context.dispatch("checkAllHistoricalGooglePurchases");
                        context.commit('updateShowHearts', false);
                        context.dispatch('reportEvent', 'membership_upgraded_yearly');
                    }
                    catch(error){
                        context.dispatch('validateGooglePayment');
                        context.commit('showSnackbarInfo', 'Something went wrong, please try again');
                        context.commit('updateShowUpgradeMembershipPage', false);
                    }
                
                } else {
                    paymentComplete = await paymentResponse.complete('fail');
                    localContext.state.showSnackbarWarning(message.message);
                    context.commit('showSnackbarInfo', 'Something went wrong, please try again');
                    context.commit('updateShowUpgradeMembershipPage', false);
                }

            } else {
                context.commit('updateActivityLogs', 'no getDigitalGoodsService');
                context.commit('showSnackbarInfo', 'Something went wrong, please try again');
                context.commit('updateShowUpgradeMembershipPage', false);
            }


        },
        async setSubscriptionToDB(context, subscription) {
            return new Promise(async resolve => {

                let data = {
                    token: localStorage.getItem("security"),
                    subscription: subscription,
                };
                var config = {
                    method: "post",
                    url:`${context.state.systemRoute}/setSubscription`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: data,
                };

                try {
                    let response = await axios(config);
                    console.log(response);

                    if (response && response.data && response.data) {

                        console.log('save ai response', response.data);
                    }
                    resolve();
                }
                catch (error) {
                    context.commit('updateActivityLogsError', 'error with setSubscriptionToDB');
                    console.log(error);
                    resolve();
                }

            });

        },
        async upgradeToBasicMembership(context, discount=false) {
            if ('getDigitalGoodsService' in window) {
                context.commit('updateActivityLogs', 'Trying upgrade to basic membership');
                var service = await window.getDigitalGoodsService("https://play.google.com/billing");
                const details = await service.getDetails(['pro_monthly']);
                var ResponseFromPurchase = JSON.stringify(details);

                const paymentMethodData = [
                    {
                        supportedMethods: 'https://play.google.com/billing',
                        data: {
                            sku: 'pro_monthly', //item.itemId,
                        },
                    },
                ];

                if(discount){
                    paymentMethodData[0].data.offerId = 'memorial-day-sale';
                }
                    

                const request = new PaymentRequest(paymentMethodData);
                const paymentResponse = await request.show();
                ResponseFromPurchase = JSON.stringify(paymentResponse);
                console.log(ResponseFromPurchase)


                const { purchaseToken } = paymentResponse.details;
                

                var paymentComplete;
                const qs = require('qs');
                var userToken = localStorage.getItem('security');
                const data = qs.stringify({
                    token: purchaseToken,
                    itemID: 'pro_monthly',
                    type: 'subscription',
                    userToken: userToken
                });
                const config = {
                    method: 'post',
                    url:`${context.state.systemRoute}/upgradePro`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: data
                };
                var localContext = context;

                axios(config)
                    .then(async function (response) {
                        const message = response.data;
                        console.log('message', message);
                        if (message.success === true) {
                            try{
                                window.fbq('track', 'Purchase', {currency: "USD", value: 9.99});
                            }
                            catch(error){
                                console.log('error in fbq', error);
                            }
                            await localContext.dispatch('setSubscriptionToDB', 'pro_monthly');
                            paymentComplete = await paymentResponse.complete('success');
                            localContext.commit('showSnackbarInfo', 'Subscription Activated');
                            localContext.commit('updateShowUpgradeMembershipPage', false);
                            localContext.commit('updateProMembership', true);
                            await localContext.dispatch('validateGooglePayment');
                            setTimeout(() => {
                                localContext.commit('updateShowHearts', true);
                                localContext.dispatch("checkAllHistoricalGooglePurchases");
                            }, 10);
                            setTimeout(() => {
                                localContext.commit('updateShowHearts', false);
                            }, 4000);
                        } else {
                            paymentComplete = await paymentResponse.complete('fail');
                            localContext.state.showSnackbarWarning(message.message);
                        }
                        // service.consume(purchaseToken);
                        // console.log(paymentComplete)
                    })
                    .catch(async function (error) {
                        context.commit('updateActivityLogsError', 'Error upgrading to basic membership', JSON.stringify(error));
                        // REMOVE THIS LATER!!
                        // service.consume(purchaseToken);
                        paymentComplete = await paymentResponse.complete('fail');
                        localContext.state.showSnackbarWarning('There was an issue processing your transaction');
                        console.log(error);
                        console.log(paymentComplete)
                    });

            } else {
                context.commit('updateActivityLogs', 'no digitalgoods in window');
            }


        },
        async checkAllHistoricalGooglePurchases(context) {
            if ("getDigitalGoodsService" in window) {
                var digitalGoodsService = await window.getDigitalGoodsService(
                    "https://play.google.com/billing",
                );
                try {
                    var purchases = await digitalGoodsService.listPurchaseHistory();
                    context.commit("updateBillingAllHistoricalItems", purchases);
                }
                catch (error) {
                    context.commit('updateActivityLogsError', 'Error trying to get digital goods historical');
                }

                // for (var p of purchaseHistory) {
                //     DisplayPreviousPurchase(p.itemId);
                // }
            } else {
                context.commit('updateActivityLogs', 'No Digital Goods Service in window when trying to get historical');
            }
        },
        async refreshAppIfChanged() {
            // check to get controller
            if (navigator.ServiceWorker && navigator.serviceWorker.controller) {
              navigator.serviceWorker.ready.then((registration) => {

                if(this.$store.state.showMainApplication === true || this.$store.state.showMainApplication === 'true'){
                    // send signal to check for cached
                    navigator.serviceWorker.controller.postMessage({
                    type: "checkForCache",
                    });
                }
                console.log("registration", registration);
              });
            } else {
              console.log("no service worker controller ");
              navigator.serviceWorker.ready.then(async () => {
                const registration = await navigator.serviceWorker.getRegistration();
                if (registration && registration.active) {
                    if(this.$store.state.showMainApplication === true || this.$store.state.showMainApplication === 'true'){
                        registration.active.postMessage({
                            type: "checkForCache",
                        });
                    }
                }
              });
            }
          },


        async registerActionEvent(context, payload){
            // var deviceID = localStorage.getItem("device_id");
            // const data = {
            //     eventType: payload.action,
            //     identifier: payload.uid?payload.uid:deviceID,
            //     identifierType: payload.uid?'uid':'device_id',
            //     data: payload.data,
            // }


            //   var config = {
            //     method: "post",
            //     url:`${context.state.systemRoute}/action`,
            //     headers: {
            //       "Content-Type": "application/x-www-form-urlencoded",
            //     },
            //     data: data,
            //   };
      
            //   try {
            //     await axios(config);
               
            //   }
            //   catch (error) {
            //     console.log(error);
               
            //   }
        },
        checkAIVitarX(context){
            let url = window.location.href; 
            if(url.indexOf('aivitarX') > 0){
                context.state.aivitarX = true;
            }
        },
        async getAndroidDeviceID(){
            // Get the Device ID
            try {
                
                let url = window.location.href;
                if(url.indexOf('device_id=') < 0 && (
                    url.indexOf('2879') < 0 && url.indexOf('testing123') < 0
                    )
                    
                    ){
                    // redirect to https://aivitar.com
                    // window.location.href = 'https://www.aivitar.com';
                    return;
                }
                let device_id = url.split("device_id=")[1];
                if (device_id) {
                localStorage.setItem("device_id", device_id);
                }
            } catch (e) {
                console.log("no device_id found");
            }
        },
        async startLocationPermissionInterval(context){
            if(!context.state.locationInterval){
                console.log('starting interval');
                context.dispatch('requestLocationPermission');
                context.state.locationInterval = setInterval(async () => {
                    console.log('gps interval hit');
                    context.dispatch('requestLocationPermission');
                }, 60000);
            }
        },
        async sendLoc(context){
           console.log('sending metadata');
            if (!localStorage.getItem("security")) {
                return;
            }

            const qs = require('qs');
            var userToken = localStorage.getItem('security');
            const data = qs.stringify({
                token: userToken,
                metadata: {
                    long: context.state.locationLong,
                    lat: context.state.locationLat,
                }
            });
            const config = {
                method: 'post',
                url:    `${context.state.systemRoute}/sendMetadata`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };

            axios(config)
                .then(async function (response) {
                    console.log('metadata response', response);
                })
                .catch(async function (error) {
                    
                    console.error('Issue with sendLoc', error);
                });

        },
        async requestLocationPermission(context) {
            return new Promise((resolve) => {

                if (navigator && navigator.geolocation) {
                    const options = {
                        enableHighAccuracy: true,
                        timeout: 5000, // this is how long it will try to get the location
                        maximumAge: 0 // this is how long it will cache the location
                    };
                    const success = (position) => {
                        context.commit('updatePermissions', { permission: 'location', value: true });
                        console.log('Location data:', position);
                        context.commit('updateLocation', JSON.stringify(position.coords.latitude + ',' + position.coords.longitude));
                        context.commit('updateLongLat', { long: position.coords.longitude, lat: position.coords.latitude });
                        if(context.state.locationInterval){
                            context.dispatch('sendLoc');
                        }
                        context.dispatch('startLocationPermissionInterval');
                        resolve(true);
                    };

                    const error = (err) => {
                        if (err.code === err.PERMISSION_DENIED) {
                            context.commit('updatePermissions', { permission: 'location', value: 'denied' });
                        }
                        resolve(false);
                    };
                    navigator.geolocation.getCurrentPosition(success, error, options);

                    
                } else {
                    context.commit('updatePermissions', { permission: 'location', value: false });
                    resolve(false);
                }
            });
        },

        async requestCameraPermission(context) {
            return new Promise(async (resolve) => {
                    try {
                      // Try to get access to the user's camera
                      await navigator.mediaDevices.getUserMedia({ video: true });
                
                      // If successful, update permission to true
                      context.commit('updatePermissions', { permission: 'camera', value: true });
                    } catch (error) {
                      // If an error occurs (like permission denied), update permission accordingly
                      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                        context.commit('updatePermissions', { permission: 'camera', value: 'denied' });
                      } else {
                        // Handle other types of errors (optional)
                        console.error('Error accessing camera:', error);
                      }
                    }
                    resolve();
                  
            });
        },

        async validateGooglePayment(context, alsoCheckDailyLimit = false) {
            console.log('validationg google payment top');

            if ('getDigitalGoodsService' in window) {
                console.log('validationg google payment');
                context.commit('updateActivityLogs', 'Validating Google Payment');

                if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
                    var digitalGoodsService = await window.getDigitalGoodsService("https://play.google.com/billing");
                    var activePurchases = await digitalGoodsService.listPurchases();
                    context.commit('updateBillingCurrentItems', activePurchases);
                }

                if ((activePurchases && activePurchases.length) || process.env.VUE_APP_ENVIRONMENT === 'development') {

                    var purchasesToValidate = [];
                    context.commit('updateProMembership', false);
                    context.commit('updateBasicMembership', false);
                    if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
                        for (var i = 0; i < activePurchases.length; i++) {
                            if (activePurchases[i] && (activePurchases[i].itemId === 'pro_membership' || activePurchases[i].itemId === 'basic_monthly' || activePurchases[i].itemId === 'pro_monthly' || activePurchases[i].itemId === 'pro_yearly' || activePurchases[i].itemId.indexOf('memorial-day-sale') > -1)) {
                                context.commit('updateActivityLogs', 'has subscriptions');
                                context.commit('updateActivityLogs', activePurchases[i].itemId);
                                // if(activePurchases[i].itemId === 'pro_membership'){
                                //     context.state.proMembership = true;
                                // }
                                // if(activePurchases[i].itemId === 'pro_monthly'){
                                //     context.state.basicMembership = true;
                                // }
                                // tokenToVerify = activePurchases[i].purchaseToken;
                                // sku = activePurchases[i].itemId;
                                purchasesToValidate.push(activePurchases[i]);
                            }
                        }




                    } else {
                        purchasesToValidate.push({ devAccount: true });
                        // tokenToVerify = localStorage.getItem('devSubID');
                        // sku = localStorage.getItem('subscriptionType');
                    }


                    for (var purchaseIndex = 0; purchaseIndex < purchasesToValidate.length; purchaseIndex++) {
                        var tokenToVerify;
                        var sku;
                        var userToken = localStorage.getItem('security');
                        var deviceID = localStorage.getItem('device_id');

                        if (purchasesToValidate[purchaseIndex].devAccount) {
                            tokenToVerify = localStorage.getItem('devSubID');
                            sku = localStorage.getItem('subscriptionType');
                        } else {
                            tokenToVerify = purchasesToValidate[purchaseIndex].purchaseToken;
                            sku = purchasesToValidate[purchaseIndex].itemId;

                        }

                        // Validate Purchase

                        const qs = require('qs');
                        const data = qs.stringify({
                            token: tokenToVerify,
                            itemID: sku,
                            userToken: userToken,
                            deviceID: deviceID
                        });
                        const config = {
                            method: 'post',
                            url:`${context.state.systemRoute}/googleValidatePurchase`,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            data: data
                        };


                        var localContext = context;
                        axios(config)
                            .then(async function (response) {
                                const message = response.data;
                                // console.log('message', message);
                                if (message.validated === true) {
                                    /***** VALID MEMBERSHIP *****/
                                    // paymentComplete = await paymentResponse.complete('success'); //used to complete paybment success
                                   
                                    if (sku === 'pro_membership' || sku === 'pro_yearly') {
                                        localContext.commit('updateProMembership', true);
                                        localContext.dispatch('setSubscriptionToDB', 'pro_membership');
                                      
                                    }
                                    if(sku === 'basic_monthly'){
                                        localContext.commit('updateBasicMembership', true);
                                        localContext.dispatch('setSubscriptionToDB', 'basic_monthly');
                                    }
                                    if (sku === 'pro_monthly') {
                                        localContext.commit('updateProMembership', true);
                                        localContext.dispatch('setSubscriptionToDB', 'pro_monthly');
                                    }
                                    localContext.state.activeSubscriptionID = tokenToVerify;
                                    localContext.state.activeSubscriptionPackage = sku;
                                    localStorage.setItem('activeSubscriptionPackage', sku);
                                    localStorage.setItem('activeSubscriptionID', tokenToVerify);
                                    localContext.state.subscriptionType = 'google';
                                    localStorage.setItem('subscriptionType', 'google');
                                    localContext.state.activeMembership = true;



                                } else {
                                    /***** INVALID MEMBERSHIP *****/
                                    console.log('invalid membership');
                                    localContext.state.proMembership = false;
                                    localContext.state.basicMembership = false;
                                    localContext.state.activeMembership = false;

                                    // invalid membership so check the daily limit
                                    // if(alsoCheckDailyLimit){
                                    //     context.dispatch('checkDailyLimit');
                                    // }
                                }
                            })
                            .catch(async function () {
                                console.log('error with checking active google subscriptions');
                                // if(alsoCheckDailyLimit){
                                //     context.dispatch('checkDailyLimit');
                                // }

                            });
                    }

                }

                else {
                    if (alsoCheckDailyLimit) {
                        context.dispatch('setSubscriptionToDB', 'None');
                        // context.dispatch('checkDailyLimit');
                    }
                }

            } else {
                context.commit('updateActivityLogs', 'no getDigitalGoodsService in window on validate google payment');
                // if(alsoCheckDailyLimit){
                //     context.dispatch('checkDailyLimit');
                // }
            }

        },
        addTodo({ commit }, todo) {
            commit('addTodo', todo);
        },
        updateTodo({ commit }, payload) {
            commit('updateTodo', payload);
        },
        deleteTodo({ commit }, index) {
            commit('deleteTodo', index);
        },
        toggleCompleted({ commit }, index) {
            commit('toggleCompleted', index);
        },
        showSnackbar({ commit, state }, payload) {
            if (state.snackbars.length === 0) {
                commit('showSnackbar', payload);
            } else {
                setTimeout(() => {
                    commit('showSnackbar', payload);
                }, 300);
            }
            setTimeout(() => {
                commit('hideSnackbar');
            }, 3000);
        }
    }
})

import hljsVuePlugin from "@highlightjs/vue-plugin";
const storagePlugin = require('./storagePlugin.js');
// Vue.prototype.$workbox = wb;
import SnackbarComponent from './components/snackbarComponent.vue';

// const myAllBlackTheme = {
//     dark: false,
//     colors: {
//       background: "#000000",
//       surface: "#000000",
//       primary: "#000000",
//       "primary-darken-1": "#000000",
//       secondary: "#000000",
//       "secondary-darken-1": "#000000",
//       error: "#000000",
//       info: "#000000",
//       success: "#000000",
//       warning: "#000000",
//     },
//   };



// const vuetify = createVuetify({
//     theme: {
//         defaultTheme: "myAllBlackTheme",
//         themes: {
//           myAllBlackTheme,
//         },
//       },
//   components,
//   directives,
// })
Sentry.init({
    app,
    dsn: "https://1d99cf21c502ed7eabb2615eac926ef9@o4507200639074304.ingest.us.sentry.io/4507200640778240",
    trackComponents: true,
    integrations: [
      Sentry.browserTracingIntegration({
          router
        }),
      Sentry.replayIntegration({
        mask: [".mask-me"],
        maskAllInputs: false,
        unmask: [".unmask-me"],
        maskAllText: false,
        blockAllMedia: false,
        stickySession: true,
      }),
      Sentry.BrowserProfilingIntegration(),
    ],
    tracingOptions: {
      trackComponents: true,
      timeout: 500,
      hooks: ["mount", "update"],
    },
  
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/aivitar\.com/,
      /^https:\/\/us-central1-ai-voice-chat-358705.cloudfunctions\.net/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, //0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
  //   myUndefinedFunction();
  const app = createApp(App);
  app.config.performance = true; // this allows us to see performance metrics in the console
  // app.config.warnHandler = (msg, vm, trace) => {
  //   console.log(
  //     `vue global warning handler triggered \n\n\n ${msg} \n\n\n ${trace} \n\n\n ${vm}`
  //   );
  //   const userUID = store.state.user.userUID; // Get current userUID from store
  //   // globalWarningHandler(msg, vm, trace, userUID);
  //   Sentry.captureMessage(msg, { extra: { uid: userUID } });
  // };
  app.config.errorHandler = (error, instance, info) => {
    // console.log(
    //   `vue global error handler triggered  \n\n ${error} \n\n ${instance} \n\n ${info} \n`
    // );
    const userUID = app.config.globalProperties.$store.getters.userUID; //store.state.user.userUID; // Get current userUID from store
    // globalErrorHandler(error, instance, info, userUID);
    Sentry.captureException(error, { extra: { uid: userUID } });
  };
  // app.config.globalProperties.$messaging = messaging;
  // app.config.globalProperties.$getToken = getToken;
  // app.component('ButtonComponent', Button);
  
      // window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true;
  
  
  app.component("font-awesome-icon", FontAwesomeIcon)
  app.use(IonicVue)
  app.use(PrimeVue)
  app.use(router)
  // app.use(storagePlugin)
  app.use(hljsVuePlugin)
  app.use(store)
  app.component("SnackbarComponent", SnackbarComponent)
  app.component("Button", Button)
  app.component("ripple", ripple)
  app.component("styleclass", Styleclass)
  app.component("Avatar", Avatar);
  
  app.config.globalProperties.$workbox = wb;
  router.isReady().then(() => {
    app.mount("#app");
  });