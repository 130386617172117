const state = {
     // Speaking and Listening
     handleServerErrorResetTalking: false,
     speakToProcess: null,
     speakToProcessWordsOnly: null,
     repeatLastQuestion: false,
     isTalking: false,
     aiIsTalking: false,
     talkButtonHit: false,
     userTalking: false, // duplicate with isTalking?
     onDeviceTextToSpeech: localStorage.getItem('onDeviceTextToSpeech') != undefined ? localStorage.getItem('onDeviceTextToSpeech') || localStorage.getItem('onDeviceTextToSpeech')==="true": false,
     onDeviceAudioRecording: localStorage.getItem('onDeviceAudioRecording') != undefined ? localStorage.getItem('onDeviceAudioRecording') : true,
     selectedAvatarLanguage: null,
     selectedAvatarVoice: null,
     selectedAvatarVoiceRate: 1,
     globallyStopTalking: false,
     floatingText: '',
     floatingTextAI: '',
     lastSentences: [],
     injectedSentencesToPlay: [],
     conversationOngoing: false,
     lastSpokenSentence: null,
     isListening: false,
     useAudio: (localStorage.getItem('useAudio') === 'true'),
     isSpeaking: false, //duplicate with isTalking?
     aiTalking: false, //duplicate with aiIsTalking?
         //REVIEW THESE
         readyToSpeak: false,


}

const mutations = {
    updateHandleServerErrorResetTalking(state, payload) {
        state.handleServerErrorResetTalking = payload;
    },
    updateLastSpokenSentence(state, payload){
        state.lastSpokenSentence = payload;
    },
    updateInjectedSentencesToPlay(state, payload) {
        state.injectedSentencesToPlay = payload;
    },
    updateConversationOngoing(state, payload) {
        state.conversationOngoing = payload;
    },
    updateIsTalking(state, payload) {
        state.isTalking = payload;
    },
    updateSpeakToProcess(state, payload) {
        state.speakToProcess = payload;
    },
    updateSpeakToProcessWordsOnly(state, payload){
        state.speakToProcessWordsOnly = payload;
    },
    updateReadyToSpeak(state, payload) {
        state.readyToSpeak = payload;
    },
    updateUseAudio(state, payload) {
        state.useAudio = payload;
        localStorage.setItem('useAudio', payload);
    },
    updateRepeatLastQuestion(state, payload) {
        state.repeatLastQuestion = payload;
    },
    updateIsSpeaking(state, payload) {
        state.isSpeaking = payload;
    },
    updateUserTalking(state, payload){
        state.userTalking = payload;
    },
    updateaiTalking(state, payload) {
        console.log('[store updateaiTalking]', payload);
        state.aiTalking = payload;
    },
      updateLastSentences(state, sentence) {
            state.lastSentences.push(sentence);
        },
        clearLastSentences(state) {
            state.lastSentences = [];
        },
        updateTalkButtonHit(state, payload){
            state.talkButtonHit = payload;
        },
        updateGloballyStopTalking(state, payload) {
            state.globallyStopTalking = payload;
            state.conversationOngoing = false;
        },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
