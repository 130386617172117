var axios = require("axios"); 
const state = {
    // Intro 
    startBlur: false,
    preferredAvatarSex: localStorage.getItem('preferredAvatarSex') ? localStorage.getItem('preferredAvatarSex') : 'female',
    showAvatarSelectorIntro: false,
    bouncedEmailBlocked: localStorage.getItem('bouncedEmailBlocked')? localStorage.getItem('bouncedEmailBlocked'): false, // this stops them from trying to log in
    bouncedEmailBlockedMessage: localStorage.getItem('bouncedEmailBlockedMessage')? localStorage.getItem('bouncedEmailBlockedMessage'): false, // this is the modal hide/show
    sayIntroHello: false,
    startIntroFade: false,
    profileCompleted: (localStorage.getItem('profileCompleted') !== null) ? localStorage.getItem('profileCompleted') : false,
    profileCompletedAlmost: (localStorage.getItem('profileCompletedAlmost') === 'true'),
    profileStep: 0,
    totalSteps: 7,
    firstTimeUser: (localStorage.getItem('firstTimeUser') === 'true'), //  true,
    firstTimeUserLocked: (localStorage.getItem('firstTimeUserLocked') === 'true'), //  true,
    singleIntroSave: false,
    introAppExpectations: false,
    introCompanionPreference: false,
    introCustomerGender: false,
    preventIntroExperience: false,
    introGallery: false,
    introAvatarViewer: false,
    introVoice: false,
    introSexualHowTo: false,
    introPersonality: false,
    introInterestsSelection: false,
    selectedGalleryAvatar: localStorage.getItem('selectedGalleryAvatar')? localStorage.getItem('selectedGalleryAvatar'): null,
    introShowAsXRated: false,
    introQuickMessage: false,
    showGalleryConfirmation: false,
    introShowAsXRatedAllowed: true,
    introShowXRatedConfirmation: false,
    IntroHideShowAsXRated: false,
    showUserLoginBlue: false,
    appExpectations: localStorage.getItem('appExpectations') ? JSON.parse(localStorage.getItem('appExpectations')) :{
        romantic: false,
        tool: false,
        assistant: false,
        code: false,
        information: false 
    },
    companionPreference: localStorage.getItem('companionPreference') ? localStorage.getItem('companionPreference') : null,
    customerGender: localStorage.getItem('customerGender') ? localStorage.getItem('customerGender') : null,
    customerSelectedVoice: localStorage.getItem('customerSelectedVoice') ? localStorage.getItem('customerSelectedVoice') : null,
    customerSelectedProfile: localStorage.getItem('customerSelectedProfile') ? localStorage.getItem('customerSelectedProfile') : null,
}

const mutations = {
    updateIntroSexualHowTo(state, payload){
        state.introSexualHowTo = payload;
    },
    updateBouncedEmailBlockedMessage(state, payload) {
        console.log('updateBouncedEmailBlockedMessage', payload)
        state.bouncedEmailBlockedMessage = payload;
        localStorage.setItem('bouncedEmailBlockedMessage', payload);
    },
    updateBouncedEmailBlocked(state, payload) {
        state.bouncedEmailBlocked = payload;
        localStorage.setItem('bouncedEmailBlocked', payload);
    },
    updateSingleIntroSave(state, payload) {
        state.singleIntroSave = payload;
    },
    updateShowGalleryConfirmation(state, payload){
        state.showGalleryConfirmation = payload;
    },
    updatePreventIntroExperience(state, payload) {
        state.preventIntroExperience = payload;
        localStorage.setItem('preventIntroExperience', payload);
    },
    updateIntroShowAsXRated(state, payload) {
        if(state.introShowAsXRatedAllowed === true){
            state.introShowAsXRated = payload;
        }   
    },
    updateIntroShowXRatedConfirmation(state, payload) {
        state.introShowXRatedConfirmation = payload;
    },
    updateCustomerSelectedProfile(state, payload) {
        state.customerSelectedProfile = payload;
        localStorage.setItem('customerSelectedProfile', payload);
    },
    updateCustomerSelectedVoice(state, payload) {
        state.customerSelectedVoice = payload;
        localStorage.setItem('customerSelectedVoice', payload);
    },
    updateCustomerGender(state, payload) {
        state.customerGender = payload;
        localStorage.setItem('customerGender', payload);
    },
    updateCompanionPreference(state, payload) {
        state.companionPreference = payload;
        localStorage.setItem('companionPreference', payload);
    },
    updateAppExpectations(state, payload) {
        state.appExpectations = payload;
        localStorage.setItem('appExpectations', JSON.stringify(payload));
    },
    hideBlueLogin(state) {
        state.showUserLoginBlue = false;
    },
    updateShowUserLoginBlue(state, payload) {   
        state.showUserLoginBlue = payload;
        if(payload === true){
            state.showLoginPage = false;
        } else {
            state.showLoginPage = true;
        }
        
    },

    updateSelectedGalleryAvatar(state, payload) {
        // 
        state.selectedGalleryAvatar = payload;
        localStorage.setItem('selectedGalleryAvatar', payload);
    },
    startIntroPage(state, PageName) {
        state['intro' + PageName] = true;
    },
    stopIntroPage(state, PageName) {
        state['intro' + PageName] = false;
    },
    updateShowAvatarSelectorIntro(state, payload) {
        state.showAvatarSelectorIntro = payload;
    },
    updatePreferredAvatarSex(state, payload) {
        state.preferredAvatarSex = payload;
        localStorage.setItem('preferredAvatarSex', payload);
    },

    updateFirstTimeUser(state, payload) {
        if (payload === true && state.firstTimeUserLocked === true) {
            state.firstTimeUser = false;
            return;
        }
        if (payload === false && state.firstTimeUserLocked === true) {
            state.firstTimeUser = false;
            return;
        }
        if (payload === false && state.firstTimeUserLocked === false) {
            state.firstTimeUserLocked = true;
            state.firstTimeUser = false;
        }
        if (payload === true && state.firstTimeUserLocked === false) {
            state.firstTimeUser = true;
        }
        localStorage.setItem('firstTimeUser', state.firstTimeUser);
    },
    updateFirstTimeUserLocked(state, payload) {
        state.firstTimeUserLocked = payload;
        localStorage.setItem('firstTimeUserLocked', payload);
    },
    updateStartIntroFade(state, payload) {
        state.startIntroFade = payload;
    },
    async updateProfileCompleted(state, payload) {
        
        state.profileCompleted = payload;
        localStorage.setItem('profileCompleted', payload);

        if (payload === true) {
            let data = {
                token: localStorage.getItem("security"),
                avatar: state.avatar
            };
            var config = {
                method: "post",
                url: `${state.systemRoute}/profileCompleted`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data: data,
            };

            try {
                await axios(config);

                //now save the voice, profile, gender etc.
                this.$store.dispatch("saveProfileToDB");
            }
            catch (error) {
                console.log(error);
            }

        }
    },
    updateProfileStep(state, payload) {
        state.profileStep = payload;
    },
}

const actions = {

}

module.exports = {
    state,
    mutations,
    actions
}
