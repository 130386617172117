const state = {
    showStore: false,
            storeItems: [
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },
                {
                    image: 'assets/avatar/sarah/clothing/vneck.webp',
                    title: 'v-neck shirt',
                    description: "A beautiful blue dress for Sarah",
                    numberOfTokens: 100,
                },

            ],
            showWebsite: true,
            websitePage: 'home',
}

const mutations = {
    updateWebsitePage(state, payload){
        state.websitePage = payload;
    },
    updateShowWebsite(state, payload){
        state.showWebsite = payload;
    },
    updateShowStore(state, payload){
        state.showStore = payload;
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
