import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-739c0e22"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "handleAIResponse"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.messages.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, function (message, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      "class": "handleAIResponse__message"
    }, _toDisplayString(message), 1);
  }), 128))])) : _createCommentVNode("", true);
}