const state = {
  /* Membership */
  plan: "",

  upgradeModalMessage: "",
  checkingMembership: true, // true
  proMembership: localStorage.getItem("proMembership") ? localStorage.getItem("proMembership") === "true" : false,
  basicMembership:localStorage.getItem("basicMembership") ? localStorage.getItem("basicMembership") === "true" : false,
  activeMembership: false,
  neverHadMembership: false,
  showUpgradeMembershipPage: false,
  upgradeMessage: "",
  billingCurrentItems: [],
  billingAllHistoricalItems: [],
};

const mutations = {
  selectPlan(state, plan) {
    state.plan = plan;
    state.showSignup = true;
    state.showHomepage = false;
  },
  updateCheckingMembership(state) {
    state.checkingMembership = false;
  },
  updateUpgradeMessage(state, payload) {
    state.upgradeMessage = payload;
  },

  updateProMembership(state, payload) {
    state.proMembership = payload;
    // state.activeMembership = payload;
    localStorage.setItem("proMembership", payload);
    // localStorage.setItem("activeMembership", payload);
  },
  updateBasicMembership(state, payload) {
    state.basicMembership = payload;
    // state.activeMembership = payload;
    localStorage.setItem("basicMembership", payload);
    // localStorage.setItem("activeMembership", payload);
  },
  updateUpgradeModalMessage(state, payload) {
    state.upgradeModalMessage = payload;
  },
  updateShowUpgradeMembershipPage(state, payload) {
    state.showUpgradeMembershipPage = payload;
  },
};

const actions = {};

module.exports = {
  state,
  mutations,
  actions,
};
