import _objectSpread from "/home/joey/projects/aivitar/talk-to-ai/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.date.to-json.js";
import { fabric } from "fabric";
import { mapMutations } from "vuex";
export default {
  name: 'DrawingCanvas',
  data: function data() {
    return {
      canvas: null,
      color: "#000000",
      brushSize: 5
    };
  },
  mounted: function mounted() {
    console.log('<<<<<<<<< DrawingCanvas mounted >>>>>>>>>');
    if (this.$store.state.drawingJSONToLoad) {
      this.canvas.loadFromJSON(this.$store.state.drawingJSONToLoad);
      this.setDrawingJSON(null);
    }
    this.canvas = new fabric.Canvas(this.$refs.canvas, {
      isDrawingMode: true
    });
    this.canvas.freeDrawingBrush.color = this.color;
    this.canvas.freeDrawingBrush.width = this.brushSize;
    this.canvas.setHeight(window.innerHeight);
    this.canvas.setWidth(window.innerWidth);
    window.addEventListener("resize", this.updateCanvasSize);
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener("resize", this.updateCanvasSize);
  },
  watch: {
    color: function color(newColor) {
      this.canvas.freeDrawingBrush.color = newColor;
    },
    brushSize: function brushSize(newSize) {
      this.canvas.freeDrawingBrush.width = newSize;
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations(['setDrawingJSON'])), {}, {
    updateCanvasSize: function updateCanvasSize() {
      this.canvas.setHeight(window.innerHeight);
      this.canvas.setWidth(window.innerWidth);
    },
    download: function download() {
      //download & save as image
      var dataURL = this.canvas.toDataURL({
        format: "png"
      });
      var link = document.createElement("a");
      link.href = dataURL;
      link.download = "my_sketch.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    save: function save() {
      var json = this.canvas.toJSON();
      this.setDrawingJSON(json);

      //save as image
      // const dataURL = this.canvas.toDataURL({ format: "png" });
      // const link = document.createElement("a");
      // link.href = dataURL;
      // link.download = "drawing.png";
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    }
  })
};