"use strict";

// var localStorage = window.localStorage;
import "core-js/modules/web.timers.js";
export default {
  name: "BottomBar",
  components: {},
  props: {
    title: {
      type: String,
      "default": "Talking to"
    }
  },
  beforeMount: function beforeMount() {},
  mounted: function mounted() {
    this.closeEverything();
    this.$store.commit("updateViewChatHistory", true);
    this.$store.commit("updateTextOnlyMode", true);
  },
  watch: {
    "$store.state.showAIVitarSettingsMenu": "hideFloatingImage"
  },
  methods: {
    hideFloatingImage: function hideFloatingImage() {
      // if the menu is open then hide the floating image as it is in the way
      this.$store.commit('updateShowFloatingImage', !this.$store.state.showAIVitarSettingsMenu);
    },
    showAppsAction: function showAppsAction() {
      var _this = this;
      this.showApps = true;
      setTimeout(function () {
        _this.showApps = false;
      }, 5000);
    },
    closeEverything: function closeEverything() {
      this.$store.commit("updateShowMainMenu", false);
      this.$store.commit("updateShowAvatarSettings", false);
      this.$store.commit("updateShowAudioView", false);
      this.$store.commit("updateShowCalls", false);
      this.$store.commit("updateShowNotes", false);
      this.$store.commit("updateShowToDoList", false);
      this.$store.commit("updateShowMap", false);
      this.$store.commit("updateViewChatHistory", false);
      this.$store.commit("updateUseAudio", false);
      this.$store.commit("voiceOff");
      this.$store.commit("updateTextOnlyMode", false);
    }
  },
  data: function data() {
    return {
      showApps: false
    };
  }
};