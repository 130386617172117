var url = window.location.href;
var domain = url.split('/')[2];

const state = {
    website: 'aivitar.com', //NU
    appName: 'AIVitar', //U
    companyName: 'Solution Sidekick LLC', 
    appVersion: '1.0.0',
    buildNumber: '0',
    installedVersion: localStorage.getItem('installedVersion') != undefined ? localStorage.getItem('installedVersion') : '0.0.0',
    supportEmail: 'support@aivitar.com',
    supportEmailLink: '<a href="mailto:support@aivitar.com">support@aivitar.com</a>',
    systemRoute: (process.env.VUE_APP_ENVIRONMENT === "development" || domain === 'dev.aivitar.com')?
    "https://us-central1-ai-voice-chat-358705.cloudfunctions.net": "",
    billingPlatform: 'android',
}

const mutations = {

}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
