import { defineComponent } from "vue";
export default defineComponent({
  name: "ImageCarousel",
  props: {
    stateSlideSelector: {
      type: String,
      "default": null
    },
    showNextButtons: {
      type: Boolean,
      "default": false
    },
    hideCloseButton: {
      type: Boolean,
      "default": false
    },
    showArrows: {
      type: Boolean,
      "default": false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      currentIndex: 0,
      startX: 0
    };
  },
  mounted: function mounted() {
    console.log('<<<<<<<<< ImageCarousel mounted >>>>>>>>>');
    this.updateSlideSelector();
  },
  computed: {
    carouselWrapperStyle: function carouselWrapperStyle() {
      return {
        transform: "translateX(-".concat(this.currentIndex * 100, "%)")
      };
    }
  },
  methods: {
    dynamicImageStyle: function dynamicImageStyle(item) {
      if (item.imageStyles) {
        return item.imageStyles;
      }
      if (!item.imageWidth && !item.imageHeight) {
        return {
          width: "auto",
          "max-height": "250px",
          height: "300px",
          "object-fit": "cover"
        };
      }
      if (item.imageWidth && item.imageHeight) {
        return {
          width: "".concat(item.imageWidth),
          height: "".concat(item.imageHeight)
        };
      }
      if (item.imageWidth && !item.imageHeight) {
        return {
          width: "".concat(item.imageWidth),
          height: "auto"
        };
      }
      if (item.imageHeight && !item.imageWidth) {
        return {
          width: "auto",
          height: "".concat(item.imageHeight)
        };
      }
    },
    updateSlideSelector: function updateSlideSelector() {
      if (this.stateSlideSelector) {
        this.$store.commit('updateSlideSelector', {
          slideSelector: this.stateSlideSelector,
          slideIndex: this.currentIndex
        });
      }
    },
    next: function next() {
      if (this.currentIndex < this.items.length - 1) {
        this.currentIndex++;
        this.updateSlideSelector();
      }
    },
    prev: function prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateSlideSelector();
      }
    },
    goTo: function goTo(index) {
      this.currentIndex = index;
    },
    handleTouchStart: function handleTouchStart(event) {
      this.startX = event.changedTouches[0].screenX;
    },
    handleTouchEnd: function handleTouchEnd(event) {
      var endX = event.changedTouches[0].screenX;
      if (this.startX - endX > 50) {
        this.next();
      } else if (this.startX - endX < -50) {
        this.prev();
      }
    }
  }
});