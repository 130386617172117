import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "ring-status"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": "BluetoothRingController-ring-control",
    onTouchstart: _cache[2] || (_cache[2] = function () {
      return $options.onTouchStart && $options.onTouchStart.apply($options, arguments);
    }),
    onTouchend: _cache[3] || (_cache[3] = function () {
      return $options.onTouchEnd && $options.onTouchEnd.apply($options, arguments);
    }),
    style: _normalizeStyle({
      'pointer-events': _ctx.$store.state.bluetoothControllerConnected ? 'auto' : 'none'
    })
  }, [_ctx.$store.state.bluetoothControllerConnected ? (_openBlock(), _createElementBlock("p", _hoisted_1, [_createTextVNode("Remote Active "), _createElementVNode("button", {
    "class": "stopbutton",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handleCenterClick && $options.handleCenterClick.apply($options, arguments);
    })
  }, "Stop")])) : _createCommentVNode("", true), _createElementVNode("div", {
    "class": "click-zone",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.handleCenterClick && $options.handleCenterClick.apply($options, arguments);
    })
  })], 36);
}