const state = {
            // camera
            showVideo: false,
            videoFrequencyInMS: 100,
            isCapturingVideo: false,
            VideoButtonLabel: 'Start Capturing'
}

const mutations = {
    updateIsCapturingVideo(state, payload){
        state.isCapturingVideo = payload;
        if(payload === true){
            state.VideoButtonLabel = 'Stop Capturing';
        } else {
            state.VideoButtonLabel = 'Start Capturing';
        }
    },
    addImageFromVideo(state, payload){
        state.photo = payload;
        state.smallPhoto = true;
    },
    updateShowVideo(state, payload){
        console.log('triggetring camera');
        state.showVideo = false;
        state.photo = null;
        state.photoToApprove = null;
        state.smallPhoto = false;
        setTimeout(() => {
        state.showVideo = payload;
        }, 100);
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
