import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-261fd656"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "releaseNotes"
};
var _hoisted_2 = {
  "class": "releaseNotes__scrollable"
};
var _hoisted_3 = {
  "class": "release__version"
};
var _hoisted_4 = {
  "class": "release__title"
};
var _hoisted_5 = {
  "class": "release__notes"
};
var _hoisted_6 = ["innerHTML"];
var _hoisted_7 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.releases, function (release, releaseIndex) {
    return _openBlock(), _createElementBlock("div", {
      "class": "release",
      key: "rIdx".concat(releaseIndex)
    }, [_createElementVNode("div", _hoisted_3, "Version " + _toDisplayString(release.version), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(release.title), 1), _createElementVNode("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(release.notes, function (releaseNote, noteIndex) {
      return _openBlock(), _createElementBlock("li", {
        "class": "release__note",
        key: "rnIdx".concat(noteIndex),
        innerHTML: releaseNote
      }, null, 8, _hoisted_6);
    }), 128))]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(release.images, function (releaseImage, imageIndex) {
      return _openBlock(), _createElementBlock("img", {
        "class": "release__image",
        key: "riIdx".concat(imageIndex),
        src: releaseImage.src,
        alt: releaseImage.alt
      }, null, 8, _hoisted_7);
    }), 128))]);
  }), 128))])]);
}