import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ca8670a8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "ActivityLogs"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  key: 1,
  "class": "ActivityLogs__messageError"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return _ctx.$store.state.showActivityLogs ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_font_awesome_icon, {
    icon: "fa-times",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$store.commit('updateShowActivityLogs', false);
    }),
    "class": "fas fa-check closebutton"
  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.ActivityLogs, function (log, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      "class": "ActivityLogs__message"
    }, [log.error == false ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      "class": "ActivityLogs__message",
      innerHTML: log.message
    }, null, 8, _hoisted_2)) : _createCommentVNode("", true), log.error == true ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(log.message), 1)) : _createCommentVNode("", true)]);
  }), 128))])) : _createCommentVNode("", true);
}