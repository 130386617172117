const state = {
    searchLocations: '',
    location: null,
    locationLong: null,
    locationLat: null,
}

const mutations = {
    updateSearchLocations(state, payload) {
        state.searchLocations = payload;
    },
    updateLongLat(state, payload) {
        state.locationLong = payload.long;
        state.locationLat = payload.lat;
        localStorage.setItem('location', payload);
        localStorage.setItem('longitude',  payload.long);
        localStorage.setItem('latitude',  payload.lat);
    },
    updateLocation(state, payload) {
        state.location = payload;
        localStorage.setItem('longLat', payload);
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
