export default {
  name: 'ShowXRated',
  components: {
    // ButtonBasic,
    // ToggleComponent,
  },
  mounted: function mounted() {
    console.log('<<<<<<<<< ShowXRated mounted >>>>>>>>>');
    if (this.$store.state.proMembership || this.$store.state.basicMembership) {
      this.$store.commit('updateIntroShowAsXRated', true);
    }
  },
  methods: {
    disableXrated: function disableXrated() {
      this.$store.commit('updateIntroShowAsXRated', false);
    },
    showXRated: function showXRated() {
      if (this.$store.state.proMembership || this.$store.state.basicMembership) {
        this.$store.commit('updateIntroShowAsXRated', true);
      } else {
        this.$store.commit('updateIntroShowXRatedConfirmation', true);
      }
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  }
};