const state = {
    aiModel: (localStorage.getItem('aiModel') != undefined ? localStorage.getItem('aiModel') : 'default'),
    appSettingsRightHanded: localStorage.getItem('appSettingsRightHanded')?localStorage.getItem('appSettingsRightHanded')==='true':false,
    userUID: localStorage.getItem('userUID')? localStorage.getItem('userUID'):null,
    userDisplayName: null,
    userEmail: localStorage.getItem('userEmail')? localStorage.getItem('userEmail'):null,
    userFirstName: '',
    userLastName: '',
    useActivationWord: localStorage.getItem('useActivationWord')? localStorage.getItem('useActivationWord'):false,
    activationWord: 'mary',
    exceptionalMode: (localStorage.getItem('exceptionalMode') === 'true'),
    enableCodeResponses: (localStorage.getItem('enableCodeResponses') === 'true'),
            
            
            
    // do these!
    "chatSettings_sentence_length": "short", // "short", "medium", "long"
    "chatSettings_voice": "active", // "active", "passive"
    "chatSettings_vocabulary": "standard", // "simple", "standard", "advanced"
    "chatSettings_specific_phrasing": "include", // "include", "exclude"
    "chatSettings_focus": "general", // "general", "specific"
    "chatSettings_detail_level": "overview", // "overview", "in-depth"
    "chatSettings_perspective": "neutral", // "neutral", "positive", "negative"
    "chatSettings_comparisons": "none", // "none", "relevant"
    "chatSettings_personalization": "default", // "default", "custom"
    "chatSettings_speculation": "factual", // "factual", "hypothetical"
    "chatSettings_question_form": "direct", // "direct", "indirect"
    "chatSettings_qualifiers": "none", // "none", "some"
    "chatSettings_sentence_emphasis": "subject", // "subject", "object"fa
    "chatSettings_rhetorical_devices": "none", // "alliteration", "assonance", "none"
    "chatSettings_figurative_language": "literal", // "literal", "metaphor", "simile"
    "chatSettings_sentence_order": "subject-verb-object", // "subject-verb-object", "object-subject-verb"
    "chatSettings_conditional_sentences": "real", // "real", "unreal"
    "chatSettings_idiomatic_expressions": "none", // "none", "some"
    "chatSettings_humor": "none", // "none", "some"
    "chatSettings_puns": "none", // "none", "some"
    "chatSettings_politeness_level": "polite", // "polite", "less_polite"
    "chatSettings_person": "first", // "first", "second", "third"
    "chatSettings_gender_neutral_language": "gender_specific", // "gender_specific", "gender_neutral"
    "chatSettings_verb_tense": "present", // "present", "past", "future"
    "chatSettings_speech_form": "direct", // "direct", "indirect"
    "chatSettings_certainty_level": "certain", // "certain", "uncertain"
    "chatSettings_word_polarity": "positive", // "positive", "negative"
    "chatSettings_cultural_references": "none", // "none", "some"
    "chatSettings_hyperbole": "none", // "none", "some"
    "chatSettings_parentheses_or_dashes": "parentheses", // "parentheses", "dashes"
    "chatSettings_enumerations": "enumeration", // "enumeration", "non-enumeration"
    "chatSettings_repetition": "none", // "none", "some"
    "chatSettings_personification": "none", // "none", "some"
    "chatSettings_rhetorical_questions": "none", // "none", "some"
    "chatSettings_quotes_or_references": "none", // "none", "some"
    "chatSettings_cliches": "none", // "none", "some"
}

const mutations = {
    updateUseGemini(){
        this.updateAIModel('gemini');
        localStorage.setItem('aiModel', 'gemini');
    },
    updateUserUID(state, payload) {
        state.userUID = payload;
        localStorage.setItem('userUID', payload);
    },
    updateUserEmail(state, payload){
        state.userEmail = payload;
        localStorage.setItem('userEmail', payload);
    },
    updateAIModel(state, payload) {
        state.aiModel = payload;
        localStorage.setItem('aiModel', payload);
    },
    updateAppSettingsRightHanded(state, payload) {
        state.appSettingsRightHanded = payload;
        localStorage.setItem('appSettingsRightHanded', payload);
    },
    enableExceptionalMode(state) {
        state.exceptionalMode = true;
        localStorage.setItem('exceptionalMode', true);
    },
    disableExceptionalMode(state) {
        state.exceptionalMode = false;
        localStorage.setItem('exceptionalMode', false);
    },





    updateSentenceLength(state, payload) {
        state.chatSettings_sentence_length = payload;
    },
    updateIdiomaticExpressions(state, payload) {
        state.chatSettings_idiomatic_expressions = payload;
    },
    // updatePolitenessLevel(state, payload) {
    //     state.chatSettings_politeness_level = payload;
    // },
    updatePersonalization(state, payload) {
        state.chatSettings_person = payload;
    },
    updateGenderNeutralLanguage(state, payload) {
        state.chatSettings_gender_neutral_language = payload;
    },
    updateSpeechForm(state, payload) {
        state.chatSettings_speech_form = payload;
    },
    updateCertaintyLevel(state, payload) {
        state.chatSettings_certainty_level = payload;
    },
    updateWordPolarity(state, payload) {
        state.chatSettings_word_polarity = payload;
    },
    updateHyperbole(state, payload) {
        state.chatSettings_hyperbole = payload;
    },
    updateParenthesesOrDashes(state, payload) {
        state.chatSettings_parentheses_or_dashes = payload;
    },
    updateEnumerations(state, payload) {
        state.chatSettings_enumerations = payload;
    },
    updatePersonification(state, payload) {
        state.chatSettings_personification = payload;
    },
    updateRhetoricalQuestions(state, payload) {
        state.chatSettings_rhetorical_questions = payload;
    },
    updateCliches(state, payload) {
        state.chatSettings_cliches = payload;
    },
    updatePuns(state, payload) {
        state.chatSettings_puns = payload;
    },

  
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
