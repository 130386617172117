const state = {

    useContext: localStorage.getItem('useContext')? localStorage.getItem('useContext')=='true' : true,
    simpleResponses: localStorage.getItem('simpleResponses')? localStorage.getItem('simpleResponses') == 'true' : false,
    useMemories: localStorage.getItem('useMemories')? localStorage.getItem('useMemories') == 'true' : false,
    storeMemories:localStorage.getItem('storeMemories')? localStorage.getItem('storeMemories')=='true' : false,
    continuousListenMode: localStorage.getItem('continuousListenMode')? localStorage.getItem('continuousListenMode')=='true':false,
    continuousListeningActive: false,
    brillianceMode: localStorage.getItem('brillianceMode')?localStorage.getItem('brillianceMode')=='true':false,
    textOnlyMode: localStorage.getItem('textOnlyMode')?localStorage.getItem('textOnlyMode')=='true':false,
}

const mutations = {
    enableContinuousListenMode(state) {
        state.continuousListenMode = true;
        localStorage.setItem('continuousListenMode', true);
    },
    disableContinuousListenMode(state) {
        state.continuousListenMode = false;
        localStorage.setItem('continuousListenMode', false);
    },
    updateStoreMemories(state, payload) {
        state.storeMemories = payload;
        localStorage.setItem('storeMemories', payload);
    },
    updateContinuousListeningActive(state, payload) {
        state.continuousListeningActive = payload;
        // localStorage.setItem('continuousListeningActive', payload);
    },
    enableContext(state) {
        state.useContext = true;
        localStorage.setItem('useContext', true);
    },
    disableContext(state) {
        state.useContext = false;
        localStorage.setItem('useContext', false);
    },
    updateContext(state, payload) {
        state.useContext = payload;
        localStorage.setItem('useContext', payload);
    },

}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
