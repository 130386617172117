const state = {
            // limit reached
            limitReached: false,
            limitReachedDate: localStorage.getItem('limitReachedDate') != undefined ? localStorage.getItem('limitReachedDate') : null,
            showLimitsReachedPage: false,

            // Subscriptions
            nextPackage: null,
            activeSubscriptionID: localStorage.getItem('activeSubscriptionID') != undefined ? localStorage.getItem('activeSubscriptionID') : null,
            activeSubscriptionPackage: localStorage.getItem('activeSubscriptionPackage') != undefined ? localStorage.getItem('activeSubscriptionPackage') : null,
            subscriptionType: localStorage.getItem('subscriptionType') != undefined ? localStorage.getItem('subscriptionType') : null,
            
}

const mutations = {
    justShowLimitsReachedPage(state) {
        state.showLimitsReachedPage = true;
    },
    updateLimitReachedAndShowModal(state, payload) {
        console.log('[store updateLimitReached]', payload);
        if (payload === true) {
            // get today's date
            var todaysDate = new Date();
            state.limitReached = true;
            state.limitReachedDate = todaysDate;
            localStorage.setItem('limitReachedDate', todaysDate);
            localStorage.setItem('limitReached', true);
            state.showLimitsReachedPage = true;
        } else {
            state.limitReached = false;
            state.limitReachedDate = null;
            localStorage.setItem('limitReachedDate', null);
            localStorage.setItem('limitReached', false);
            state.showLimitsReachedPage = false;
        }

    },
    updateLimitReached(state, payload) {
        console.log('[store updateLimitReached]', payload);
        if (payload === true) {
            // get today's date
            var todaysDate = new Date();
            state.limitReached = true;
            state.limitReachedDate = todaysDate;
            localStorage.setItem('limitReachedDate', todaysDate);
            localStorage.setItem('limitReached', true);
        } else {
            state.limitReached = false;
            state.limitReachedDate = null;
            localStorage.setItem('limitReachedDate', null);
            localStorage.setItem('limitReached', false);
            state.showLimitsReachedPage = false;
        }

    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
