import "core-js/modules/web.timers.js";
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SnackbarComponent',
  computed: {
    visible: function visible() {
      var snackbars = this.$store.state.snackbars;
      return snackbars.length > 0 ? snackbars[snackbars.length - 1].visible : false;
    },
    color: function color() {
      var snackbars = this.$store.state.snackbars;
      return snackbars.length > 0 ? snackbars[snackbars.length - 1].color : '';
    },
    text: function text() {
      var snackbars = this.$store.state.snackbars;
      return snackbars.length > 0 ? snackbars[snackbars.length - 1].text : '';
    },
    textColor: function textColor() {
      var snackbars = this.$store.state.snackbars;
      return snackbars.length > 0 ? snackbars[snackbars.length - 1].textColor : 'black';
    }
  },
  methods: {
    hide: function hide() {
      this.$store.commit('hideSnackbar');
    }
  },
  mounted: function mounted() {
    var _this = this;
    console.log('<<<<<<<<< snackbarComponent mounted >>>>>>>>>');
    setTimeout(function () {
      _this.hide();
    }, 3000);
  }
});