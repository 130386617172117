const state = {
            // Texting 
            textQuestion: '',
            textViewWaitingForReplay: false,
            showChatActionsOverlay: false,
            lastChatOpenActionItem: [], //stores the index and indexC of open messages
            chatList: [],
            conversationList: (localStorage.getItem('conversationList')) ? JSON.parse(localStorage.getItem('conversationList')) : [], //[['Hello', 'Say hello!']],
           
}

const mutations = {
    updateTextQuestion(state, payload) {
        state.textQuestion = payload;
    },
    addToConversation(state, payload) {
        // get curent date in MM-DD-YYYY format
        const date = new Date();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        const dateString = month + '-' + day + '-' + year;

        // get current time in 12 hours format
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        const timeString = (hours > 12 ? hours - 12 : hours) + ':' + minutes + ' ' + ampm;

        const listItemZero = {
            text: payload[0],
            popupVisible: false,
            date: dateString,
            time: timeString,
        }
        const listItemOne = {
            text: payload[1],
            popupVisible: false,
            date: dateString,
            time: timeString,
        }

        // function insertIntoFirstSingleItemArray(arrayOfArrays, aiComment) {
        //     for (let i = 0; i < arrayOfArrays.length; i++) {
        //         if (Array.isArray(arrayOfArrays[i]) && arrayOfArrays[i].length === 1) {
        //             arrayOfArrays[i].push(aiComment);
        //             break;
        //         }
        //     }
        //     return arrayOfArrays;
        // }

        
        // if this is the second part of the conversation from chat, insert the response from AI into the correct place
        if(Number.isInteger(payload[0])){
            var currentList = state.conversationList;
            var currentListLength = currentList.length;
            var actual = currentListLength - payload[0];

            currentList[actual].push(listItemOne);
            // var newlyConstructedList = insertIntoFirstSingleItemArray(state.conversationList, listItemOne);
            state.conversationList = [];
            state.conversationList = currentList;
            localStorage.setItem('conversationList', JSON.stringify(state.conversationList));
        } else {

            // if this is a standard insert
            let list = [];
            // if we have a second part, insert the whole thing
            if (payload[1]) {
                list = [listItemZero, listItemOne];

                //if we only have the first part (the user query) then insert just that.
            } else {
                list = [listItemZero];
            }

            // add it to the beginning of the list
            state.conversationList.unshift(list);
            localStorage.setItem('conversationList', JSON.stringify(state.conversationList));
        }
        var convoNumber = state.conversationWatcher;
        state.conversationWatcher = convoNumber + 1;

    },
    updateViewChatHistory(state, payload) {
        state.showChatHistory = payload;
    },

    clearChatList(state){
        state.chatList = [];
        localStorage.setItem('chatList', JSON.stringify(state.chatList));
    },
    addToConversationFront(state, payload) {

        // get curent date in MM-DD-YYYY format
        const date = new Date();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        const dateString = month + '-' + day + '-' + year;

        // get current time in 12 hours format
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        const timeString = (hours > 12 ? hours - 12 : hours) + ':' + minutes + ' ' + ampm;

        const listItemZero = {
            text: payload[0],
            popupVisible: false,
            date: dateString,
            time: timeString,
        }
        const listItemOne = {
            text: payload[1],
            popupVisible: false,
            date: dateString,
            time: timeString,
        }

        // function insertIntoFirstSingleItemArray(arrayOfArrays, aiComment) {
        //     for (let i = 0; i < arrayOfArrays.length; i++) {
        //         if (Array.isArray(arrayOfArrays[i]) && arrayOfArrays[i].length === 1) {
        //             arrayOfArrays[i].push(aiComment);
        //             break;
        //         }
        //     }
        //     return arrayOfArrays;
        // }

        
        // if this is the second part of the conversation from chat, insert the response from AI into the correct place
        if(Number.isInteger(payload[0])){
            var currentList = state.conversationList;
            var currentListLength = currentList.length;
            var actual = currentListLength - payload[0];

            currentList[actual].push(listItemOne);
            // var newlyConstructedList = insertIntoFirstSingleItemArray(state.conversationList, listItemOne);
            state.conversationList = [];
            state.conversationList = currentList;
            localStorage.setItem('conversationList', JSON.stringify(state.conversationList));
        } else {

            // if this is a standard insert
            let list = [];
            // if we have a second part, insert the whole thing
            if (payload[1]) {
                list = [listItemZero, listItemOne];

                //if we only have the first part (the user query) then insert just that.
            } else {
                list = [listItemZero];
            }

            // add it to the beginning of the list
            state.conversationList.push(list);
            localStorage.setItem('conversationList', JSON.stringify(state.conversationList));
        }
        var convoNumber = state.conversationWatcher;
        state.conversationWatcher = convoNumber + 1;

    },

    closeLastChatPopup(state) {
        state.showChatActionsOverlay = false;
        state.conversationList[state.lastChatOpenActionItem[0]][state.lastChatOpenActionItem[1]].popupVisible = false;
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
