import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import { Bar, Line, Pie, Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, ArcElement } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, ArcElement);
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BarChart',
  components: {
    Bar: Bar,
    Line: Line,
    Pie: Pie,
    Doughnut: Doughnut
  },
  props: {
    chartType: {
      type: String,
      "default": 'bar'
    },
    chartId: {
      type: String,
      "default": 'chart'
    },
    datasetIdKey: {
      type: String,
      "default": 'label'
    },
    width: {
      type: Number,
      "default": 400
    },
    height: {
      type: Number,
      "default": 400
    },
    cssClasses: {
      "default": '',
      type: String
    }
  },
  data: function data() {
    return {
      chartOptions: {
        // no animation
        animation: {
          duration: 0
        },
        // change legend color and size
        responsive: true,
        scales: {
          x: {
            grid: {
              color: '#000'
            },
            ticks: {
              color: 'black',
              font: {
                weight: 'bold'
              }
            }
          },
          y: {
            grid: {
              color: '#000'
            },
            ticks: {
              color: '#000'
            }
          }
        }
      }
    };
  },
  mounted: function mounted() {
    setTimeout(function () {
      try {
        var chartCanvas = document.getElementById('chart');
        if (chartCanvas && chartCanvas.style && chartCanvas.style) {
          chartCanvas.style.height = '100%';
        }
      } catch (e) {
        console.log(e);
      }
    }, 1000);

    //
  },
  methods: {
    updateChart: function updateChart() {
      if (this.$store && this.$store.state && this.$store.state.chartData) {
        this.chartData = this.$store.state.chartData;
      }
    },
    closeChart: function closeChart() {
      this.$store.commit('updateViewChart');
    }
  }
});