const state = {
    showWalkThrough:false,
    timesWalkthroughPresented: localStorage.getItem('timesWalkthroughPresented')? localStorage.getItem('timesWalkthroughPresented') : 0,
    showSkipWalkthroughForever: false,
    skipWalkthroughForever: localStorage.getItem('skipWalkthroughForever')? localStorage.getItem('skipWalkthroughForever') : false,
    hasTakenTour: localStorage.getItem('hasTakenTour')? localStorage.getItem('hasTakenTour') : false,
    TutorialPointerMainMenu: false,
    TutorialPointerConvo: false,
    TutorialPointerMemories: false,
    TutorialPointerContext: false,
    TutorialPointerResponses: false,
    TutorialPointerAssistant: false,
    incrementWalkthrough: false,
    TutorialPointerApps: false,
    TutorialPointerVideo: false,
    TutorialPointerChat: false,
    TutorialPointerMap: false,
    TutorialPointerAvatar: false,


    TutorialPointerReplay: false,
    TutorialPointerInsert: false,
    TutorialPointerSound: false,
    TutorialPointerSpeak: false,


    TutorialPointerLoveMeter: false,
}

const mutations = {
    checkToSkipWalkthroughForever(state) {
        let timesPresented = parseInt(state.timesWalkthroughPresented);
        if(timesPresented >= 1){
            state.showSkipWalkthroughForever = true;
        } else {
            state.showSkipWalkthroughForever = false;
        }
    },
    updateSkipWalkthroughForever(state, payload) {
        state.skipWalkthroughForever = payload;
        localStorage.setItem('skipWalkthroughForever', payload);
    },
    incrementTimesWalkthroughPresented(state) {
        let timesPresented = parseInt(state.timesWalkthroughPresented);
        timesPresented++;
        localStorage.setItem('timesWalkthroughPresented', timesPresented);
        state.timesWalkthroughPresented = timesPresented;
    },
    updateIncrementWalkthrough(state, payload) {
        state.incrementWalkthrough = payload;
    },
    updateHasTakenTour(state, payload) {
        state.hasTakenTour = payload;
        localStorage.setItem('hasTakenTour', payload);
    },
    tutorialPointerOn(state, pointer) {
        state.TutorialPointerConvo=false;
        state.TutorialPointerMemories= false;
        state.TutorialPointerContext= false;
        state.TutorialPointerResponses= false;
        state.TutorialPointerAssistant= false;
        state.TutorialPointerApps=false;
        state.TutorialPointerVideo=false;
        state.TutorialPointerChat=false;
        state.TutorialPointerMap=false;
        state.TutorialPointerAvatar=false;
        state['TutorialPointer'+pointer] = true;
    },
    tutorialPointerAllOff(state, payload) {
        console.log('turning all pointers off', payload);
        state.TutorialPointerMainMenu=false;
        state.TutorialPointerConvo=false;
        state.TutorialPointerMemories=false;
        state.TutorialPointerContext=false;
        state.TutorialPointerResponses=false;
        state.TutorialPointerAssistant=false;
        state.incrementWalkthrough=false;
        state.TutorialPointerApps=false;
        state.TutorialPointerVideo=false;
        state.TutorialPointerChat=false;
        state.TutorialPointerMap=false;
        state.TutorialPointerAvatar=false;
    
    
        state.TutorialPointerReplay=false;
        state.TutorialPointerInsert=false;
        state.TutorialPointerSound=false;
        state.TutorialPointerSpeak=false;
    
    
        state.TutorialPointerLoveMeter=false;
    },
    tutorialPointerOff(state, pointer) {
        state['TutorialPointer'+pointer] = false;
    },

}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
