const state = {
  //quiz
  showQuizGame: false,
  quizTopic: null,
  quizMe: false,
  quizCorrect:0,
  quizIncorrect:0,
  quizNextQuestion:null,
  gameShowConversation: [],

}

const mutations = {
    updateGameShowConversation(state, payload) {
        state.gameShowConversation.push(payload);
    },
    updateQuizNextQuestion(state, payload) {
        state.quizNextQuestion = payload;
    },
    updateQuizResults(state, payload) {
        state.quizCorrect = payload.totalCorrect;
        state.quizIncorrect= payload.totalIncorrect;
      
    },
    updateQuizScore(state,payload){
        state.quizCorrect = state.quizCorrect + payload.totalCorrect;
        state.quizIncorrect = state.quizIncorrect + payload.totalIncorrect;
    },
    updateShowQuizGame(state, payload) {
        state.showQuizGame = payload;


    },
    updateQuizTopic(state, payload) {
        state.quizTopic = payload;
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
