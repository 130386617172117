import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ButtonBasic',
  props: {
    label: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    //
  },
  methods: {}
});