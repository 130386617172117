import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1c4d42f9"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "CameraComponent"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 2,
  "class": "CameraComponent__capture",
  dblclick: "switchCamera"
};
var _hoisted_5 = ["width", "height"];
var _hoisted_6 = {
  ref: "canvasElement",
  style: {
    "display": "none"
  }
};
var _hoisted_7 = {
  key: 3,
  "class": "CameraCompont__show"
};
var _hoisted_8 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.$store.state.photo || _ctx.$store.state.photo && !_ctx.$store.state.smallPhoto ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
    key: 0,
    icon: ['fa', 'times'],
    "class": "closeButton",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      $options.closeCamera();
    })
  })) : _createCommentVNode("", true), _ctx.$store.state.photo && !_ctx.$store.state.smallPhoto ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    "class": "CameraComponent__max",
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$store.commit('minimizePhoto');
    })
  }, [_createElementVNode("img", {
    "class": "photo__max",
    src: _ctx.$store.state.photo,
    alt: "Captured Image"
  }, null, 8, _hoisted_2), _createElementVNode("button", {
    "class": "buttonAction",
    onClick: _cache[1] || (_cache[1] = function () {
      return $options.clearPhoto && $options.clearPhoto.apply($options, arguments);
    })
  }, "Stop Using")])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", {
    "class": "CameraComponent__min",
    style: _normalizeStyle({
      top: "".concat($setup.y, "px"),
      left: "".concat($setup.x, "px")
    }),
    onMousedown: _cache[3] || (_cache[3] = function () {
      return $setup.startDrag && $setup.startDrag.apply($setup, arguments);
    }),
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.$store.commit('maximizePhoto');
    })
  }, [_createElementVNode("img", {
    "class": "photo__min",
    src: _ctx.$store.state.photo,
    alt: "Captured Image"
  }, null, 8, _hoisted_3)], 36), [[_vShow, _ctx.$store.state.photo && _ctx.$store.state.smallPhoto]]), _ctx.$store.state.photoToApprove === null && !_ctx.$store.state.photo ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("video", {
    "class": "videoWindow",
    ref: "videoElement",
    width: $data.imageWidth,
    height: $data.imageHeight,
    autoplay: "",
    onTouchstart: [_cache[5] || (_cache[5] = function () {
      return $options.focusCamera && $options.focusCamera.apply($options, arguments);
    }), _cache[6] || (_cache[6] = function () {
      return $options.startZoom && $options.startZoom.apply($options, arguments);
    })],
    onTouchmove: _cache[7] || (_cache[7] = function () {
      return $options.zoom && $options.zoom.apply($options, arguments);
    }),
    onTouchend: _cache[8] || (_cache[8] = function () {
      return $options.endZoom && $options.endZoom.apply($options, arguments);
    })
  }, null, 40, _hoisted_5), $data.showImageCaptureButton ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    "class": "snapButton",
    onClick: _cache[9] || (_cache[9] = function () {
      return $options.captureImage && $options.captureImage.apply($options, arguments);
    })
  })) : _createCommentVNode("", true), $data.devices.length > 1 ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
    key: 1,
    icon: ['fad', 'camera-rotate'],
    "class": "switchCamera",
    onClick: $options.switchCamera
  }, null, 8, ["onClick"])) : _createCommentVNode("", true), _createElementVNode("canvas", _hoisted_6, null, 512)])) : _createCommentVNode("", true), _ctx.$store.state.photoToApprove != null && !_ctx.$store.state.photo ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("img", {
    "class": "imageCaptured",
    src: _ctx.$store.state.photoToApprove,
    alt: "Captured Image"
  }, null, 8, _hoisted_8), _createElementVNode("button", {
    "class": "buttonAction--higher buttonAction",
    onClick: _cache[10] || (_cache[10] = function () {
      return $options.saveImage && $options.saveImage.apply($options, arguments);
    })
  }, " Use Photo "), _createElementVNode("button", {
    "class": "buttonAction--smaller buttonAction",
    onClick: _cache[11] || (_cache[11] = function () {
      return $options.restartCamera && $options.restartCamera.apply($options, arguments);
    })
  }, [_createVNode(_component_font_awesome_icon, {
    icon: ['fa', 'chevron-left']
  })])])) : _createCommentVNode("", true)]);
}