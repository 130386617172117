import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.string.substr.js";
import ButtonBasic from '@/components/form/ButtonBasic.vue';
import ToggleComponent from '@/components/form/ToggleComponent.vue';
//   import { ref } from 'vue';

export default {
  name: 'CallsAutoAssistForm',
  components: {
    ButtonBasic: ButtonBasic,
    ToggleComponent: ToggleComponent
  },
  mounted: function mounted() {
    console.log('<<<<<<<<< CallsAutoAssistForm mounted >>>>>>>>>');
    // setTimeout(() => {
    // const dateInput = document.getElementById('preferenceDate');

    //     dateInput.addEventListener('focus', () => {
    //         // Set the cursor position to the end of the input text
    //         dateInput.setSelectionRange(dateInput.value.length, dateInput.value.length);
    //     });

    //     dateInput.addEventListener('click', (event) => {
    //         // Check if the input is not focused on the mm/dd/yyyy part
    //         if (event.target.value === '') {
    //             // Open the calendar view
    //             dateInput.dispatchEvent(new MouseEvent('mousedown', { 'view': window, 'bubbles': true, 'cancelable': true }));
    //         }
    //     });
    // }, 1000);
  },
  methods: {
    openCalendar: function openCalendar(event) {
      var dateInput = this.$refs.calendarBtn;
      if (event.target.value === '') {
        // Open the calendar view
        dateInput.dispatchEvent(new MouseEvent('mousedown', {
          'view': window,
          'bubbles': true,
          'cancelable': true
        }));
      }
    },
    addSchedule: function addSchedule() {
      if (!this.preferenceDate || (!this.preferenceTime || !this.preferenceTime2) && !this.preferenceTimeAllDay) {
        this.$store.commit("showSnackbarWarning", "Please fill out all fields");
        return;
      }

      // Function to convert military time to standard time with AM/PM
      var convertToStandardTime = function convertToStandardTime(time) {
        var hours = parseInt(time.substr(0, 2), 10);
        var minutes = time.substr(3, 2);
        var ampm = "AM";
        if (hours >= 12) {
          ampm = "PM";
          if (hours > 12) {
            hours -= 12;
          }
        } else if (hours === 0) {
          hours = 12;
        }
        return "".concat(hours.toString().padStart(2, "0"), ":").concat(minutes).concat(ampm);
      };
      var time1 = convertToStandardTime(this.preferenceTime);
      var time2 = convertToStandardTime(this.preferenceTime2);
      this.schedulingPreferences.push({
        date: this.preferenceDate,
        time: time1,
        time2: time2,
        allDay: this.preferenceTimeAllDay
      });
      this.$store.commit('showSnackbarAdd', 'Preference Added');
      this.preferenceDate = "";
      this.preferenceTime = "";
      this.preferenceTime2 = "";
    },
    removeItemFromSchedulingPreferences: function removeItemFromSchedulingPreferences(index) {
      this.schedulingPreferences.splice(index, 1);
    },
    onToggleChange: function onToggleChange(value) {
      this.saveUserInformationLocally = value;
      console.log('Toggle state:', this.saveUserInformationLocally);
    }
  },
  setup: function setup() {
    //   const section = ref(1);

    // Add the rest of your data properties here
    //   const confirmedPhoneNumber = ref(false);
    //   const userFirstName = ref('');
    //   const businessName = ref('');

    //   const submitForm = () => {
    //     // Handle form submission
    //     console.log('Form submitted');
    //   };
  },
  data: function data() {
    return {
      section: 2,
      // confirm phone number
      confirmedPhoneNumber: false,
      //user informationschedulingType
      behalfOfLastName: '',
      userFirstName: '',
      userLastName: '',
      userPhoneCountryCode: '',
      // 1 - use default dropdown
      userPhoneAreaCode: '',
      userPhone2: '',
      schedulingRelationship: '',
      userPhone3: '',
      userCity: '',
      userState: '',
      userZip: '',
      schedulingOnBehalfOf: 'Myself',
      userAddress: '',
      preferenceDate: '',
      preferenceTime: '',
      preferenceTime2: '',
      preferenceTimeAllDay: false,
      // Business Information 
      saveUserInformationLocally: true,
      saveUserInformationCloud: true,
      saveBusinessInformationLocally: false,
      // none, local, cloud - toggle (with cloud)
      saveBusinessInformationCloud: false,
      // none, local, cloud - toggle (with local)
      businessName: '',
      businessAddress: '',
      businessCity: '',
      businessState: '',
      businessPhone: '',
      schedulingType: 'Appointment',
      //
      schedulingPreferences: [] // arrays of dates and times (calendar and time range pickers)
      /* format for scheduling preferences [{date: 'Monday January 11th', startTime: '9:00am', endTime: '10:00am'}, {date: 'Tuesday January 12th', anytime(checkbox to select anytime during business hours)}] */
    };
  }
};