import "core-js/modules/es.array.concat.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-61b8e40a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["onClick", "src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  var _directive_touch_pin = _resolveDirective("touch-pin");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.FloatingImageURLs, function (imageURL, index) {
    return _openBlock(), _createElementBlock("div", {
      key: imageURL
    }, [_ctx.$store.state.showFloatingImage ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      "class": _normalizeClass({
        'floatingImage__viewer': true,
        'floatingImage__viewer-top': _ctx.$store.state.floatingImageIsLarge[index]
      }),
      style: _normalizeStyle({
        transform: "rotate(".concat(_ctx.$store.state.floatingImageIsLarge[index] === true ? 0 : index * 10, "deg)")
      })
    }, [_createVNode(_component_font_awesome_icon, {
      icon: "fa-circle-xmark",
      "class": "floatingImage__close-button",
      onClick: _cache[0] || (_cache[0] = function ($event) {
        _ctx.$store.commit('updateShowFloatingImage', false);
      })
    }), _withDirectives(_createElementVNode("img", {
      onClick: function onClick($event) {
        return _ctx.$store.commit('toggleFloatingImageSize', index);
      },
      style: _normalizeStyle({
        transform: "rotate(".concat(_ctx.$store.state.floatingImageIsLarge[index] ? '0deg' : '-9deg', ") scale(").concat(_ctx.scale, ")"),
        transformOrigin: 'center'
      }),
      src: imageURL,
      "class": _normalizeClass({
        'floatingImage': true,
        'floatingImage__large': _ctx.$store.state.floatingImageIsLarge[index]
      })
    }, null, 14, _hoisted_1), [[_directive_touch_pin, _ctx.handlePinch(index)]])], 6)) : _createCommentVNode("", true)]);
  }), 128);
}