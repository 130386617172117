const state = {
    hideBluetoothController: false,
    bluetoothControllerConnected: false,
}

const mutations = {
    updateHideBluetoothController(state, payload) {
        state.hideBluetoothController = payload;
    },
    updateBluetoothControllerConnected(state, payload){
        state.bluetoothControllerConnected = payload;
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
