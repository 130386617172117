import "core-js/modules/es.array.concat.js";
export default {
  name: 'BluetoothRingController',
  data: function data() {
    return {
      status: 'Waiting for input...',
      touchStartX: 0,
      touchEndX: 0,
      touchStartY: 0,
      touchEndY: 0,
      swipeThreshold: 50,
      // Minimum distance to be considered a swipe

      swipeVerticalThreshold: 10,
      // Threshold to classify swipe as primarily vertical

      swipeHorizontalThreshold: 10 // Threshold to classify swipe as primarily horizontal
    };
  },
  methods: {
    handleRingEvent: function handleRingEvent(event) {
      switch (event.type) {
        case 'swipeDown':
          this.swipeDown();
          break;
        case 'swipeLeft':
          this.swipeLeft();
          break;
        case 'swipeRight':
          this.swipeRight();
          break;
        default:
          console.log('Unknown event type:', event.type);
      }
    },
    swipeDown: function swipeDown() {
      console.log('swipe down');
      this.status = 'Swiped down';
      this.notifyAndroid('onSwipeDown');
      this.$store.commit('updateTalkButtonHit', true);
    },
    swipeLeft: function swipeLeft() {
      console.log('swipe left');
      this.status = 'Swiped left';
      this.notifyAndroid('onSwipeLeft');
    },
    swipeRight: function swipeRight() {
      console.log('swipe right');
      this.status = 'Swiped right';
      this.notifyAndroid('onSwipeRight');
    },
    onTouchStart: function onTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.touchStartY = event.touches[0].clientY;
    },
    onTouchEnd: function onTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      this.touchEndY = event.changedTouches[0].clientY;
      var distanceX = this.touchEndX - this.touchStartX;
      var distanceY = this.touchEndY - this.touchStartY;
      console.log("Touch End: (".concat(this.touchEndX, ", ").concat(this.touchEndY, ")"));
      console.log("DistanceX: ".concat(distanceX, ", DistanceY: ").concat(distanceY));
      var isVertical = Math.abs(distanceY) > Math.abs(distanceX);
      var isSwipe = Math.abs(isVertical ? distanceY : distanceX) > this.swipeThreshold;
      if (isSwipe) {
        if (isVertical && distanceY > this.swipeVerticalThreshold) {
          this.swipeDown();
        } else if (!isVertical && distanceX > this.swipeHorizontalThreshold) {
          this.swipeRight();
        } else if (!isVertical && distanceX < -this.swipeHorizontalThreshold) {
          this.swipeLeft();
        }
      }
    },
    notifyAndroid: function notifyAndroid(action) {
      if (window.AndroidInterface && typeof window.AndroidInterface[action] === 'function') {
        window.AndroidInterface[action]();
      }
    },
    // Handling the click event in the center of the screen
    handleCenterClick: function handleCenterClick() {
      console.log('center clicked, turning off bluetooth controller');
      this.$store.commit('updateBluetoothControllerConnected', !this.$store.state.bluetoothControllerConnected);
    }
  },
  mounted: function mounted() {
    window.addEventListener('bluetoothRingEvent', this.handleRingEvent);
  },
  beforeUnmount: function beforeUnmount() {
    window.removeEventListener('bluetoothRingEvent', this.handleRingEvent);
  }
};