// Define your props here if you aren't using <script setup>
import { defineComponent } from "vue";
export default defineComponent({
  name: "AccordionTabs",
  props: {
    tabNames: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      // tabNames: ['Tab1', 'Tab2', 'Tab3'],
      activeTabIndex: 0
    };
  },
  methods: {
    toggleTab: function toggleTab(index) {
      if (this.activeTabIndex === index) {
        this.activeTabIndex = -1;
      } else {
        this.activeTabIndex = index;
      }
    }
  }
});