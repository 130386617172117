import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2a15c985"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "chartContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return _ctx.$store.state.showChartView ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_font_awesome_icon, {
    "class": "closeIconChart",
    icon: "window-close",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.closeChart();
    })
  }), (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$store.state.chartType), {
    "chart-options": _ctx.chartOptions,
    "chart-data": _ctx.$store.state.chartData,
    "chart-id": _ctx.chartId,
    "dataset-id-key": _ctx.datasetIdKey,
    "css-classes": _ctx.cssClasses,
    width: _ctx.width,
    height: _ctx.height
  }, null, 8, ["chart-options", "chart-data", "chart-id", "dataset-id-key", "css-classes", "width", "height"]))])) : _createCommentVNode("", true);
}