import "core-js/modules/es.object.keys.js";
// import ButtonBasic from '@/components/form/ButtonBasic.vue';
// import ToggleComponent from '@/components/form/ToggleComponent.vue';
//   import { ref } from 'vue';

export default {
  name: 'AppExpectations',
  components: {
    // ButtonBasic,
    // ToggleComponent,
  },
  mounted: function mounted() {
    console.log('<<<<<<<<< AppExpectations mounted >>>>>>>>>');
    if (this.$store.state.appExpectations) {
      var appExpectations;
      try {
        appExpectations = JSON.parse(this.$store.state.appExpectations);
      } catch (error) {
        appExpectations = this.$store.state.appExpectations;
        console.log('error setting app expectations', error);
      }
      this.romantic = appExpectations.romantic || false;
      this.tool = appExpectations.tool || false;
      this.assistant = appExpectations.assistant || false;
      this.code = appExpectations.code || false;
      this.information = appExpectations.information || false;
    }
  },
  methods: {
    nextPage: function nextPage() {
      if (!this.romantic && !this.tool && !this.assistant && !this.code && !this.information) {
        return;
      }
      try {
        // update expectations for later use
        this.$store.commit('updateAppExpectations', {
          romantic: this.romantic,
          tool: this.tool,
          assistant: this.assistant,
          code: this.code,
          information: this.information
        });
      } catch (error) {
        console.log('error setting app expectations', error);
      }
      this.$store.commit('stopIntroPage', 'AppExpectations');
      if (!this.romantic) {
        // Not Romantic So show Companion Preference

        this.$store.commit('startIntroPage', 'CompanionPreference');
      } else {
        console.log('trying to show intro sexual hjow to');
        // romantic so show the sexual how to instructions
        this.$store.commit('startIntroPage', 'SexualHowTo');
        // this.$store.commit('updateIntroSexualHowTo', true);
      }
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      romantic: false,
      tool: false,
      assistant: false,
      code: false,
      information: false
    };
  }
};