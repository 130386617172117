import { Workbox } from "workbox-window";

let wb;

if ("serviceWorker" in navigator) {
  // wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);
    wb = new Workbox(`service-worker.js`);

  wb.addEventListener("controlling", () => {
    console.log('registerServiceWorker is reloading now....')
    window.location.reload();
  });

  wb.register();
} else {
  wb = null;
}

export default wb;

// /* eslint-disable no-console */

// import { register } from 'register-service-worker'

// // if (process.env.NODE_ENV === 'production') {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     ready () {
//       console.log(
//         'App is being served from cache by a service worker.\n' +
//         'For more details, visit https://goo.gl/AFskqB'
//       )

//       console.log('adding event listener')
//       this.addEventListener("fetch", (event) => {
//         console.log("fetching");
//         console.log('\n\n\n', event)
//         event.respondWith(
//           caches.match(event.request).then((response) => {
//             // caches.match() always resolves
//             // but in case of success response will have value
//             if (response !== undefined) {
//               return response;
//             } else {
//               return fetch(event.request)
//                 .then((response) => {
//                   // response may be used only once
//                   // we need to save clone to put one copy in cache
//                   // and serve second one
//                   let responseClone = response.clone();
      
//                   caches.open("v1").then((cache) => {
//                     cache.put(event.request, responseClone);
//                   });
//                   return response;
//                 })
//                 .catch(() => caches.match("/gallery/myLittleVader.jpg"));
//             }
//           })
//         );
//       });
//     },
//     registered () {
//       console.log('Service worker has been registered.')
//     },
//     cached (item) {
//       console.log('Content has been cached for offline use.')
//     },
//     updatefound () {
//       console.log('New content is downloading.')
//     },
//     updated () {
//       console.log('New content is available; please refresh.')
//     },
//     offline () {
//       console.log('No internet connection found. App is running in offline mode.')
//     },
//     error (error) {
//       console.error('Error during service worker registration:', error)
//     }
//   })
// // }
