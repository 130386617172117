"use strict";

// var localStorage = window.localStorage;
export default {
  name: "WalkthroughPointer",
  components: {},
  props: {
    title: {
      type: String,
      "default": "Talking to"
    }
  },
  beforeMount: function beforeMount() {},
  mounted: function mounted() {},
  methods: {
    // 
  },
  data: function data() {
    return {
      // showApps: false,
    };
  }
};