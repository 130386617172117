const state = {
    appType: 'Android',

}


const mutations = {
    setAppType(state, payload) {
        state.appType = payload; // Android or Web
    },
    updateBluetoothControllerConnected(state, payload){
        state.bluetoothControllerConnected = payload;
    },
}

const actions = {

}

module.exports = {
    state,
    mutations,
    actions
}