const state = {
            // listening streams
            enableHandleAIResponses: true, // flip logic to re-enable streams
                //review these
                queuedOnDeviceTTSSentences: [],
                queuedOnDeviceTTSAnimations: [],
                queuedCloudTTSSentences: [],
                queueIndexMapping: {}, //holds the order to process the queue
                queueCloudTTSProcessingCounter: 0,
                queuedCloudTTSAnimations: [],
                queuedCloudAudioMetadata: [],
                queuedTTSPlaying: false,
                processingCloudTTSPayloads: false,
}

const mutations = {
    removeSingleQueuedOnDeviceTTS(state){
        state.queuedOnDeviceTTSSentences.shift();
        state.queuedOnDeviceTTSAnimations.shift();
    },
    removeSingleQueuedCloudAudioMetadata(state){
        state.queuedCloudAudioMetadata.shift();
    },
    // this holds the order of the queue for lookup later
    addQueueIndexMapping(state, payload){
        state.queueIndexMapping[payload.key] = payload.value;
    },
    clearQueueIndexMapping(state){
        state.queueIndexMapping = {};
    },
    reEnableHandleAIResponses(state){
        state.enableHandleAIResponses = false;
        setTimeout(() => {
            state.enableHandleAIResponses = true;
        }, 1000);
    },
    updateQueueCloudTTSProcessingCounter(state){
        state.queueCloudTTSProcessingCounter++;
    },
    clearQueuedCloudTTS(state){
        state.queueCloudTTSProcessingCounter = 0;
        state.queuedCloudTTSSentences = [];
        state.queuedCloudTTSAnimations = [];
    },
    updateProcessingCloudTTSPayloads(state, payload){
        state.processingCloudTTSPayloads = payload;
    },
    updateQueuedTTSPlaying(state, payload){
        state.queuedTTSPlaying = payload;
    },
    updateQueuedCloudAudioMetadata(state, payload){
        console.log('pushing to queuedCloudAudioMetadata', payload);
        // insert at the index position
        state.queuedCloudAudioMetadata.push(payload);
    },
    updateQueuedOnDeviceTTSSentences(state, payload){
        state.queuedOnDeviceTTSSentences = [...state.queuedOnDeviceTTSSentences, payload];
},
    updateQueuedOnDeviceTTSAnimations(state, payload){
        // const newItem = { ...payload }; // Create a new object to preserve other data
        // state.queuedOnDeviceTTSAnimations[newItem.index] = newItem;
        state.queuedOnDeviceTTSAnimations = [...state.queuedOnDeviceTTSAnimations, payload];
    },
    addQueuedTTSPlaceholders(state){
        // state.queuedOnDeviceTTSSentences = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,];
        // state.queuedOnDeviceTTSAnimations = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,];
        state.queuedCloudTTSSentences = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,];
        state.queuedCloudTTSAnimations = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,];
    },
    updateQueuedCloudTTSSentences(state, payload){
        const newItem = { ...payload }; // Create a new object to preserve other data
        state.queuedCloudTTSSentences[payload.index] = newItem;
        // state.queuedCloudTTSSentences = [...state.queuedCloudTTSSentences, payload];
    },
    updateQueuedCloudTTSAnimations(state, payload){
        // state.queuedCloudTTSAnimations.splice(payload.index, 0, payload);
        const newItem = { ...payload }; // Create a new object to preserve other data
        state.queuedCloudTTSAnimations[payload.index] = newItem;
    },
    
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
