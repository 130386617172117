import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-23b651db"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "LocationPermissionsNeeded"
};
var _hoisted_2 = {
  "class": "modal"
};
var _hoisted_3 = {
  key: 0,
  "class": "modal-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  var _component_ImageCarousel = _resolveComponent("ImageCarousel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_Transition, {
    name: "fade"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_ctx.$store.state.permission_location == 'denied' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [!_ctx.success ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
        key: 0,
        icon: "times",
        "class": "close-icon",
        onClick: _ctx.closeModal
      }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.appFeatures.length ? (_openBlock(), _createBlock(_component_ImageCarousel, {
        key: 1,
        items: _ctx.appFeatures[_ctx.selectedAppFeature]
      }, null, 8, ["items"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])];
    }),
    _: 1
  })]);
}