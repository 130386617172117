import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
// import ButtonBasic from '@/components/form/ButtonBasic.vue';
// import ToggleComponent from '@/components/form/ToggleComponent.vue';
//   import { ref } from 'vue';
// var CryptoJS = require("crypto-js");
import ShowXrated from './showXRated.vue';
import IntroGalleryConfirmation from './IntroGalleryConfirmation.vue';
import axios from 'axios';
export default {
  name: 'IntroGallery',
  components: {
    ShowXrated: ShowXrated,
    IntroGalleryConfirmation: IntroGalleryConfirmation
    // ButtonBasic,
    // ToggleComponent,
  },
  mounted: function mounted() {
    console.log('<<<<<<<<< IntroGallery mounted >>>>>>>>>');
  },
  methods: {
    getImage: function getImage(aivitar) {
      if (this.$store.state.introShowAsXRated) {
        return aivitar.nudes;
      } else {
        return aivitar.image;
      }
    },
    getImageAnimated: function getImageAnimated(aivitar) {
      if (aivitar.animated) {
        return "assets/avatar/".concat(aivitar.name.toLowerCase(), "/animations/").concat(aivitar.name.toLowerCase(), ".webp");
      } else {
        if (this.$store.state.introShowAsXRated) {
          return aivitar.nudes;
        } else {
          return aivitar.image;
        }
      }
    },
    calculateProAIVitars: function calculateProAIVitars() {
      var count = 0;
      var preference = this.$store.state.companionPreference;
      for (var i = 0; i < this.$store.state.avatarList.length; i++) {
        if (this.$store.state.avatarList[i].isPro && this.$store.state.avatarList[i].isFemale && (preference === 'female' || preference === 'no preference')) {
          count++;
        }
        if (this.$store.state.avatarList[i].isPro && !this.$store.state.avatarList[i].isFemale && (preference === 'male' || preference === 'no preference')) {
          count++;
        }
      }
      return count;
    },
    selectAvatar: function selectAvatar(aivitar) {
      // avatar is a pro avatar
      if (this.$store.state.introShowAsXRatedAllowed && aivitar.isPro) {
        // user has pro membershp
        if (this.$store.state.proMembership === true || this.$store.state.proMembership === 'true' || this.$store.state.basicMembership === true || this.$store.state.basicMembership === 'true') {
          //send data for selection page 
          this.$store.commit('updateSelectedGalleryAvatar', aivitar);
          if (aivitar.fullyAnimated) {
            // hide this page 
            this.$store.commit('stopIntroPage', 'Gallery');
            this.$store.commit('startIntroPage', 'AvatarViewer');
          } else {
            this.$store.commit('updateShowGalleryConfirmation', true);
          }

          // user is not pro so show confirmation 
        } else {
          this.$store.commit('updateIntroShowXRatedConfirmation', true);
        }
        return;

        // avatar is not pro
      } else {
        //send data for selection page 
        this.$store.commit('updateSelectedGalleryAvatar', aivitar);

        // hide this page 
        this.$store.commit('stopIntroPage', 'Gallery');
        this.$store.commit('startIntroPage', 'AvatarViewer');
      }
    },
    checkHidden: function checkHidden(aivitar) {
      if ((this.$store.state.appExpectations.romantic == 'false' || this.$store.state.appExpectations.romantic == false) && aivitar.isPro) {
        return true;
      }
      if (aivitar.isFemale && (this.$store.state.companionPreference === 'female' || this.$store.state.companionPreference === 'no preference')) {
        return false;
      }
      if (!aivitar.isFemale && (this.$store.state.companionPreference === 'male' || this.$store.state.companionPreference === 'no preference')) {
        return false;
      }
      return true;
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      showMaleAvatars: true,
      showFemaleAvatars: true,
      showPassword: false,
      showLoader: false,
      message: '',
      axios: axios,
      showLogin: false,
      showRegistration: true,
      showVerification: false,
      showRedirectToCheckout: false,
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      passwordVerify: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      address1: '',
      address2: '',
      country: '',
      emailError: false,
      passwordError: false,
      firstnameError: false,
      lastnameError: false
    };
  }
};