const state = {
    ActivityLogs: [],
    showActivityLogs: false,
}

const mutations = {
    updateActivityLogs(state, message) {
        var payload = {
            error: false,
            message: message
        }
        state.ActivityLogs.push(payload);
    },
    updateActivityLogsError(state, message) {
        var payload = {
            error: true,
            message: message
        }
        state.ActivityLogs.push(payload);
    },
    clearActivityLogs(state) {
        state.ActivityLogs = [];
    },
    updateShowActivityLogs(state, payload) {
        state.showActivityLogs = payload;
    },

}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
