'use strict';

import ButtonBasic from '@/components/form/ButtonBasic.vue';
import CallsAutoAssistForm from '@/components/CallsAutoAssistForm.vue';
export default {
  name: 'CallsComponent',
  components: {
    ButtonBasic: ButtonBasic,
    CallsAutoAssistForm: CallsAutoAssistForm
  },
  methods: {
    mounted: function mounted() {
      console.log('<<<<<<<<< CallsComponent mounted >>>>>>>>>');
      //CallsComponent__title
    }
  },
  data: function data() {
    return {
      showForm: false
    };
  }
};