export default {
  name: 'IntroQuickMessage',
  mounted: function mounted() {
    this.$store.commit('updateHideBluetoothController', true);
    console.log('<<<<<<<<< IntroQuickMessage mounted >>>>>>>>>');
  },
  methods: {
    nextPage: function nextPage() {
      this.$store.commit('stopIntroPage', 'QuickMessage');
      this.$store.commit('startIntroPage', 'AppExpectations');
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      romantic: false,
      tool: false,
      assistant: false,
      code: false,
      information: false
    };
  }
};