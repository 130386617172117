const state = {

    showLocationPermissionsScreen: false,
    showCameraPermissionsScreen: false,
    permission_video: localStorage.getItem('permission_camera')?localStorage.getItem('permission_camera'):false,
    permission_audio: false,
    permission_location: localStorage.getItem('permission_location')?localStorage.getItem('permission_location'):false,
    permission_notifications: false,
    permission_camera: localStorage.getItem('permission_camera')?localStorage.getItem('permission_camera'):false,
    permission_microphone: false,
    permission_speech: false,
    permissions_trigger_video: false,
    permissions_trigger_audio: false,
    permissions_trigger_location: false,
    permissions_trigger_notifications: false,
    permissions_trigger_camera: false,
    permissions_trigger_microphone: false,
    permissions_trigger_speech: false,
}

const mutations = {
    updateShowLocationPermissionsScreen(state, value) {
        state.showLocationPermissionsScreen = value;
    },
    updateShowCameraPermissionScreen(state, value) {
        console.log('clicking to update camera permissions  screen');
        state.showCameraPermissionsScreen = value;
    },
    updateTriggerPermissions(state, payload) {
        if (payload.permission == 'notifications') {
            state.permissions_trigger_notifications = payload.value;
        }
        if (payload.permission == 'camera') {
            state.permissions_trigger_camera = payload.value;
        }
        if (payload.permission == 'microphone') {
            state.permissions_trigger_microphone = payload.value;
        }
        if (payload.permission == 'speech') {
            state.permissions_trigger_speech = payload.value;
        }

        if (payload.permission == 'video') {
            state.permissions_trigger_video = payload.value;

        }
        if (payload.permission == 'audio') {
            state.permissions_trigger_audio = payload.value;
        }
        if (payload.permission == 'location') {
            state.permissions_trigger_location = payload.value;
        }
    },

    updatePermissions(state, payload) {
        if (payload.permission == 'notifications') {
            state.permission_notifications = payload.value;
        }
        if (payload.permission == 'camera') {
            state.permission_camera = payload.value;
            localStorage.setItem('permission_camera', payload.value);
        }
        if (payload.permission == 'microphone') {
            state.permission_microphone = payload.value;
        }
        if (payload.permission == 'speech') {
            state.permission_speech = payload.value;
        }

        if (payload.permission == 'video') {
            state.permission_video = payload.value;
            localStorage.setItem('permission_camera', payload.value);

        }
        if (payload.permission == 'audio') {
            state.permission_audio = payload.value;
        }
        if (payload.permission == 'location') {
            state.permission_location = payload.value;
            localStorage.setItem('permission_location', payload.value);
        }
    },
    updateShowPermissionsScreen(state, value) {
        state.showPermissionsScreen = value;
    },
}

const actions = {
    
}

module.exports = {
    state,
    mutations,
    actions
}
