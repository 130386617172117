import "core-js/modules/web.timers.js";
// import ButtonBasic from '@/components/form/ButtonBasic.vue';
// import ToggleComponent from '@/components/form/ToggleComponent.vue';
//   import { ref } from 'vue';

export default {
  name: 'IntroSexualHowTo',
  components: {
    // ButtonBasic,
    // ToggleComponent,
  },
  mounted: function mounted() {
    //     setTimeout(() => {
    //   this.isButtonActive = true;
    // }, 1000);
    this.setButtonTimer();
    // this.calculateTotalPages();
    // console.log('<<<<<<<<< IntroSexualHowTo mounted >>>>>>>>>');
    // if (this.$store.state.selectedGalleryAvatar.isFemale) {
    //     this.actualVoiceList = this.femaleVoiceList;
    // } else {
    //     this.actualVoiceList = this.maleVoiceList;
    // }
  },
  methods: {
    listenToOrgasm: function listenToOrgasm() {
      var video = document.getElementById("orgasm");

      // Set default volume (0.0 to 1.0)
      video.volume = 1.0; // Full volume
    },
    setButtonTimer: function setButtonTimer() {
      var _this = this;
      this.isButtonActive = false;
      setTimeout(function () {
        _this.isButtonActive = true;
      }, 4000);
    },
    nextSection: function nextSection() {
      if (this.currentStep >= 8) {
        this.$store.commit('stopIntroPage', 'SexualHowTo');
        this.$store.commit('startIntroPage', 'CompanionPreference');
      } else {
        this['ad' + this.currentStep] = false;
        this.currentStep++;
        this['ad' + this.currentStep] = true;
        this.setButtonTimer();
        // Logic for moving to the next section
        console.log('Next section clicked');
      }
    },
    // calculateTotalPages() {
    //     if (this.$store.state.selectedGalleryAvatar.isFemale) {
    //         this.totalPages = Math.ceil(this.femaleVoiceList.length / this.itemsPerPage);
    //     } else {
    //         this.totalPages = Math.ceil(this.maleVoiceList.length / this.itemsPerPage);
    //     }
    // },
    backButton: function backButton() {
      this.$store.commit('stopIntroPage', 'Voice');
      this.$store.commit('startIntroPage', 'AvatarViewer');
    } // async acceptVoice() {
    //     if(!this.$store.state.customerSelectedVoice || this.$store.state.customerSelectedVoice==''){
    //         return;
    //     }
    //     await this.$store.dispatch("saveProfileToDB");
    //     //if we are just saving a single setting 
    //     if(this.$store.state.singleIntroSave===true || this.$store.state.singleIntroSave==='true'){
    //         console.log('truthy single intro save')
    //         this.$store.commit('updatePreventIntroExperience', false);
    //         // this.$store.dispatch("saveProfileToDB", 'voice');
    //         this.$store.commit('updateSingleIntroSave', false);
    //         this.$store.commit('stopIntroPage', 'Voice');
    //     } else {
    //         console.log('falsey single intro save')
    //         //saving at the end so keep going (standard flow)
    //         this.$store.commit('stopIntroPage', 'Voice');
    //         this.$store.commit('startIntroPage', 'Personality');
    //     }
    // },
    // playSample(sampleIndex) {
    //       this.actualVoiceList.forEach(voice => {
    //             voice.playing = false;
    //         });
    //     let voiceList;
    //     if (this.$store.state.selectedGalleryAvatar.isFemale) {
    //         voiceList = this.femaleVoiceList;
    //     } else {
    //         voiceList = this.maleVoiceList;
    //     }
    //     console.log('play sample', voiceList[sampleIndex].filename);
    //     var myAudio = document.getElementById("myAudio");
    //     myAudio.src = voiceList[sampleIndex].filename;
    //     myAudio.play();
    //     // while playing set playing to true
    //     voiceList[sampleIndex].playing = true;
    //     // after audio stops set playing to false
    //     myAudio.onended = function () {
    //         voiceList[sampleIndex].playing = false;
    //     };
    // },
    // selectVoice(voiceID) {
    //     this.actualVoiceList.forEach(voice => {
    //             voice.selected = false;
    //         });
    //     if (this.$store.state.selectedGalleryAvatar.isFemale) {
    //         //    set all voices to false
    //         this.femaleVoiceList.forEach(voice => {
    //             voice.selected = false;
    //         });
    //         // set selected voice to true
    //         this.femaleVoiceList[voiceID].selected = true;
    //         this.$store.commit('updateCustomerSelectedVoice', this.femaleVoiceList[voiceID].code);
    //     } else {
    //         //    set all voices to false
    //         this.maleVoiceList.forEach(voice => {
    //             voice.selected = false;
    //         });
    //         // set selected voice to true
    //         this.maleVoiceList[voiceID].selected = true;
    //         this.$store.commit('updateCustomerSelectedVoice', this.maleVoiceList[voiceID].code);
    //     }
    // }
  },
  setup: function setup() {},
  data: function data() {
    return {
      hideNext: true,
      currentStep: 1,
      ad1: true,
      ad2: false,
      ad3: false,
      ad4: false,
      ad5: false,
      ad6: false,
      ad7: false,
      ad8: false,
      ad9: false,
      ad10: false,
      isButtonActive: false
    };
  }
};