export default {
  name: "IntroGalleryConfirmation",
  components: {
    // ButtonBasic,
    // ToggleComponent,
  },
  watch: {
    "$store.state.proMembership": function $storeStateProMembership(newValue) {
      if (newValue === true) {
        this.$store.commit("updateIntroIntroGalleryConfirmation", false);
      }
    },
    "$store.state.basicMembership": function $storeStateBasicMembership(newValue) {
      if (newValue === true) {
        this.$store.commit("updateIntroIntroGalleryConfirmation", false);
      }
    }
  },
  mounted: function mounted() {
    console.log("<<<<<<<<< ShowXRated mounted >>>>>>>>>");
  },
  methods: {
    keepGoing: function keepGoing() {
      this.$store.commit('updateShowGalleryConfirmation', false);
      this.$store.commit('stopIntroPage', 'Gallery');
      this.$store.commit('startIntroPage', 'AvatarViewer');
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  }
};