import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["toggle", {
      'toggle-active': $props.active
    }])
  }, [_createElementVNode("div", {
    "class": "toggle-circle",
    style: _normalizeStyle({
      left: $props.active === true ? '50%' : 0
    })
  }, null, 4)], 2);
}