'use strict';

import ButtonBasic from '@/components/form/ButtonBasic.vue';
export default {
  name: 'PermissionsComponent',
  components: {
    ButtonBasic: ButtonBasic
  },
  props: {},
  beforeMount: function beforeMount() {},
  mounted: function mounted() {
    console.log('<<<<<<<<< PermissionsComponent mounted >>>>>>>>>');
  },
  methods: {},
  computed: {
    // this.$store.commit('updateTriggerPermissions', {permission: 'location', value: true});
    permissionsList: function permissionsList() {
      var _this = this;
      return [{
        title: 'Microphone Access',
        permission_state: this.$store.state.permission_microphone,
        clickEvent: function clickEvent() {
          return _this.$store.commit('updateTriggerPermissions', {
            permission: 'microphone',
            value: true
          });
        },
        expandClick: function expandClick() {
          _this.expandedEnabledMicrophone = !_this.expandedEnabledMicrophone;
        },
        expandEnabled: this.expandEnabledMicrophone,
        expandStoreData: function expandStoreData() {
          this.expandedStoreDataMicrophone = !this.expandedStoreDataMicrophone;
        },
        expandEnabledContent: " <p>Allowing access to your microphone allows you to speak to the AIVItars and have conversations.</p>\n          <p>When the microphone is on it is <i>very</i> apparent and you can see a couple indicators on the screen. </p>\n            <p>The most notable one is in the top right of all the screens after you log in. It says \"listening\". You can also play attention to the activation button on the bottom right for this indicator.</p> \n            \n            <p>Additionally, most phones are typically designed to play an audible sound when your microphone turns on.</p>",
        expandStoreDataContent: "<p>Recordings or audio picked up from your microphone are <b>never stored or even sent</b> to our servers.\n            <br />The audio transcription is actually performed by your device and we pass the text data to our servers to\n            be processed and return a response to your device. By design, mobile phones cannot trigger a microphone to be\n            turned on without an action from the user. So you need to first press a button in an application for the\n            device to start listening.</p>",
        buttonLabel: "Enable Microphone",
        icon: 'fa-microphone',
        blockedNotice: "<p>Whoops! Looks like the last time you went to accept the permission you blocked or denied it. In order to enable this you will now need to update the setting in the browser. Typically this is an icon directly left of the website url i.e. --HERE--http://</p>"
      }, {
        title: 'Location Access',
        permission_state: this.$store.state.permission_location,
        clickEvent: function clickEvent() {
          return _this.$store.commit('updateTriggerPermissions', {
            permission: 'location',
            value: true
          });
        },
        expandClick: function expandClick() {
          _this.expandedEnabledVideo = !_this.expandedEnabledVideo;
        },
        expandEnabled: this.expandEnabledVideo,
        expandStoreData: function expandStoreData() {
          _this.expandedStoreDataVideo = !_this.expandedStoreDataVideo;
        },
        expandEnabledContent: " <p>Allowing access to your microphone allows you to speak to the AIVItars and have conversations. When the\n            microphone is on it is <i>very</i> apparent and you can see the indicators on the screen. Additionally, most\n            phones play an audible sound when it turns on.</p>",
        expandStoreDataContent: "<p>Recordings or audio picked up from your microphone are <b>never stored or even sent</b> to our servers.\n            <br />The audio transcription is actually performed by your device and we pass the text data to our servers to\n            be processed and return a response to your device. By design, mobile phones cannot trigger a microphone to be\n            turned on without an action from the user. So you need to first press a button in an application for the\n            device to start listening.</p>",
        buttonLabel: "Enable Location",
        icon: 'fa-location-dot',
        blockedNotice: "<p>Whoops! Looks like the last time you went to accept the permission you blocked or denied it. In order to enable this you will now need to update the setting in the browser. Typically this is an icon directly left of the website url i.e. --HERE--http://</p>"
      }, {
        title: 'Notifications',
        permission_state: this.$store.state.permission_notifications,
        clickEvent: function clickEvent() {
          return _this.$store.commit('updateTriggerPermissions', {
            permission: 'notifications',
            value: true
          });
        },
        expandClick: function expandClick() {
          _this.expandedEnabledNotifications = !_this.expandedEnabledNotifications;
        },
        expandEnabled: this.expandEnabledNotifications,
        expandStoreData: function expandStoreData() {
          _this.expandedStoreDataNotifications = !_this.expandedStoreDataNotifications;
        },
        expandEnabledContent: " <p>Your AIVItars might want to reach out to you at times. Also, in lieu of emails, notifications are a great way to get information about updates / account or billing information etc. </p>",
        expandStoreDataContent: "<p>Notifications have no real data risk as it is content being pushed rather than gathered. We promise thise messages will not be \"too much\" and you can always alter them in the future in the settings.</p>",
        buttonLabel: "Enable Device Notifications",
        icon: 'fa-message',
        blockedNotice: "<p>Whoops! Looks like the last time you went to accept the permission you blocked or denied it. In order to enable this you will now need to update the setting in the browser. Typically this is an icon directly left of the website url i.e. --HERE--http://</p>"
      }];
    }
  },
  data: function data() {
    return {
      expandedEnabledMicrophone: false,
      expandedStoreDataMicrophone: false,
      expandedEnabledVideo: false,
      expandedStoreDataVideo: false,
      expandedEnabledLocation: false,
      expandedStoreDataLocation: false,
      expandedEnabledNotifications: false,
      expandedStoreDataNotifications: false,
      expandedEnabledStorage: false,
      expandedStoreDataStorage: false
    };
  }
};