export default {
  name: "ShowXRatedConfirmation",
  components: {
    // ButtonBasic,
    // ToggleComponent,
  },
  watch: {
    "$store.state.proMembership": function $storeStateProMembership(newValue) {
      if (newValue === true) {
        this.$store.commit("updateIntroShowXRatedConfirmation", false);
      }
    },
    "$store.state.basicMembership": function $storeStateBasicMembership(newValue) {
      if (newValue === true) {
        this.$store.commit("updateIntroShowXRatedConfirmation", false);
      }
    }
  },
  mounted: function mounted() {
    console.log("<<<<<<<<< ShowXRated mounted >>>>>>>>>");
  },
  methods: {
    showPayScreen: function showPayScreen() {
      this.$store.commit("updateIntroShowXRatedConfirmation", false);
      this.$store.commit("updateShowUpgradeMembershipPage", true);
    }
  },
  setup: function setup() {},
  data: function data() {
    return {};
  }
};