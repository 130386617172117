import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-57d806cc"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "NewAvatarSelector"
};
var _hoisted_2 = {
  "class": "modal"
};
var _hoisted_3 = {
  key: 0,
  "class": "modal-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  var _component_ImageCarousel = _resolveComponent("ImageCarousel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_Transition, {
    name: "fade"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_ctx.permissionGranted === false && _ctx.$store.state.permission_location != 'denied' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [!_ctx.success && _ctx.$store.state.hideCloseButtonAvatarSelector == false ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
        key: 0,
        icon: "times",
        "class": "close-icon",
        onClick: _ctx.closeModal
      }, null, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_ImageCarousel, {
        showNextButtons: "true",
        stateSlideSelector: "newAvatarSelector",
        items: _ctx.avatars
      }, null, 8, ["items"]), _renderSlot(_ctx.$slots, "default", {}, function () {
        return [_createElementVNode("button", {
          "class": "select-avatar-button",
          onClick: _cache[0] || (_cache[0] = function ($event) {
            return _ctx.selectAvatar();
          })
        }, " I want this one ")];
      }, true)])) : _createCommentVNode("", true)])];
    }),
    _: 3
  })]);
}