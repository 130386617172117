const axios = require('axios');
const qs = require("qs");
const state = {
    showDownvoteComponent: false,
    voted: false,
    downvoted: false,
    upvoted: false,
}

const mutations = {
    updateVoted(state, payload){
        state.voted = payload;
    },
    resetVotes(state){
        state.downvoted = false;
        state.upvoted = false;
        state.voted = false;
    },
    vote(state, payload){
        if(payload==='up'){
            state.upvoted = true;
            state.downvoted = false;
            state.voted = true;
        }
        if(payload==='down'){
            state.downvoted = true;
            state.upvoted = false;
            state.voted = true;
        }
       
       
    },
  
    updateShowDownvoteComponent(state, payload){
        state.showDownvoteComponent = payload;
    },
}

const actions = {
    async sendVote(context){
        var dataConfig = {
            token: localStorage.getItem("security"),
          };
          if(context.state.upvoted){
            dataConfig.voted = "positive";
          }
          if(context.state.downvoted){
            dataConfig.voted = "negative";
          }
          const data = qs.stringify(dataConfig);

          var config = {
            method: "post",
            url: `https://dev.aivitar.com/commentVote`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          };
  
          try {
            await axios(config);
          }
          catch(error){
            console.error('error sending vote', error);
          }
    }
}

module.exports = {
    state,
    mutations,
    actions
}
