import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-53657cfc"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "pressToDisable"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 0,
  "class": "proFeature"
};
var _hoisted_4 = {
  "class": "settings__top-row"
};
var _hoisted_5 = {
  "class": "settings__text"
};
var _hoisted_6 = {
  key: 0,
  "class": "settings__isActive"
};
var _hoisted_7 = {
  key: 1,
  "class": "settings__isNotActive"
};
var _hoisted_8 = {
  "class": "settings__bottom-row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass({
      'settings__item': true,
      'settings__item--disabled': !_ctx.isEnabled
    }),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.toggleState();
    })
  }, [_createElementVNode("div", _hoisted_1, [!_ctx.isEnabled && !_ctx.alwaysActive && !_ctx.proFeature || !_ctx.isEnabled && !_ctx.alwaysActive && _ctx.proFeature && _ctx.$store.state.proMembership ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.actionLabel), 1)) : _createCommentVNode("", true)]), _ctx.proFeature && !_ctx.$store.state.proMembership ? (_openBlock(), _createElementBlock("div", _hoisted_3, "PRO Feature " + _toDisplayString(!_ctx.$store.state.proMembership ? '- Click to Upgrade' : ''), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    "class": _normalizeClass({
      'settings__icon-holder': true,
      'settings__icon-holder--disabled': !_ctx.isEnabled
    })
  }, [_createVNode(_component_font_awesome_icon, {
    "class": _normalizeClass({
      'settings__icon-holder': true,
      'settings__icon-holder--disabled': !_ctx.isEnabled
    }),
    icon: _ctx.icon
  }, null, 8, ["class", "icon"])], 2), _createElementVNode("div", {
    "class": _normalizeClass({
      'settings__top-right': true,
      'settings__top-right--disabled': !_ctx.isEnabled
    })
  }, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.label), 1), _ctx.isEnabled && !_ctx.alwaysActive ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.hideOn ? ' ' : 'ON'), 1)) : _createCommentVNode("", true), !_ctx.isEnabled && !_ctx.hideOn && !_ctx.alwaysActive ? (_openBlock(), _createElementBlock("div", _hoisted_7, "OFF")) : _createCommentVNode("", true)], 2)]), _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.description), 1)], 2);
}